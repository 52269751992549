import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import lodash from 'lodash';
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import VueCookies from 'vue-cookies-ts';
import VueDragDrop from 'vue-drag-drop';
import VueLodash from 'vue-lodash';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import Vuelidate from 'vuelidate';
import App from '@/App.vue';
import vuetify from '@/plugins/vuetify';
import DayjsWrapper from '@/resources/plugins/DayjsWrapper/DayjsWrapper';
import Logger from '@/resources/plugins/Logger/Logger';
import VuelidateExtension from '@/resources/plugins/VuelidateExtension/VuelidateExtension';
import router from '@/router';
import settings from '@/settings';
import store from '@/store';
import { DomainAuth } from '@/store/modules/domain/auth';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.locale('ja');

Vue.use(VueLodash, { lodash });
Vue.use(VueCookies);
Vue.use(VueDragDrop);
Vue.use(VueClipboard);
Vue.use(Vuelidate);
Vue.use(Logger);
Vue.use(VuelidateExtension);
Vue.use(PerfectScrollbar);
Vue.use(DayjsWrapper, { dayjs });
Vue.use(Toast, settings.toast.instanceOptions);

const requireComponents = require.context('@/components/generic/', true, /Base[A-Z]\w+\.vue$/);

// 基底コンポーネントをグローバル登録
requireComponents.keys().forEach((fileName) => {
  let baseComponentConfig = requireComponents(fileName);
  baseComponentConfig = baseComponentConfig.default || baseComponentConfig;

  const baseComponentName = Vue._.upperFirst(
    baseComponentConfig.extendOptions?.name ||
      Vue._.camelCase(fileName.replace(/^.+\//, '').replace(/\.\w+$/, ''))
  );

  Vue.component(baseComponentName, baseComponentConfig);
});

Vue.config.productionTip = false;

// vuex-persistedstateのストア復元タイミングではナビゲーションガードに間に合わないため、ここで復元する
const context = DomainAuth.context(store);
context.actions.restoreAuth();

new Vue({
  name: 'Root',
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
