import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';

class UIPasswordResetState {
  // パスワード再設定受付メールアドレス
  passwordResetMailAddress = '';
}

class UIPasswordResetGetters extends Getters<UIPasswordResetState> {
  // パスワード再設定手続き中か
  passwordResetInProcess() {
    if (this.state.passwordResetMailAddress === '') {
      return false;
    }
    return true;
  }
}

class UIPasswordResetMutations extends Mutations<UIPasswordResetState> {
  clearPasswordResetMailAddress() {
    this.state.passwordResetMailAddress = '';
  }

  setPasswordResetMailAddress(payload: { email: string }) {
    this.state.passwordResetMailAddress = payload.email;
  }
}

class UIPasswordResetActions extends Actions<
  UIPasswordResetState,
  UIPasswordResetGetters,
  UIPasswordResetMutations,
  UIPasswordResetActions
> {
  // パスワード再設定受付メールアドレスをクリアする
  clearPasswordResetMailAddress() {
    this.mutations.clearPasswordResetMailAddress();
  }

  // パスワード再設定受付メールアドレスをセット
  setPasswordResetMailAddress(payload: { email: string }) {
    this.mutations.setPasswordResetMailAddress(payload);
  }
}

export const UIPasswordReset = new Module({
  actions: UIPasswordResetActions,
  getters: UIPasswordResetGetters,
  mutations: UIPasswordResetMutations,
  state: UIPasswordResetState,
});

export const UIPasswordResetMapper = createMapper(UIPasswordReset);
