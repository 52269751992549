const DayjsFormats = {
  // グラフ上の日付表示の月日表示フォーマット
  chartDisplayFormatDateMonthDay: 'M/D',

  // グラフ上の日時表示の月日時表示フォーマット
  chartDisplayFormatDateTimeMonthDayHour: 'M/D H時',

  // グラフ上の日付表示の年表示フォーマット
  chartDisplayFormatDateYear: 'YYYY',

  // グラフ上の日付表示の年月表示フォーマット
  chartDisplayFormatDateYearMonth: 'YYYY/M',

  // デートピッカー入力用のデフォルトフォーマット
  datePickerFormatDefault: 'YYYY-MM-DD',

  // デートタイムピッカー入力用のデフォルトフォーマット
  dateTimePickerFormatDefault: 'YYYY-MM-DD HH:mm:ss',

  // 日付表示のデフォルトフォーマット
  displayFormatDateDefault: 'YYYY/MM/DD',

  // 日時表示のデフォルトフォーマット
  displayFormatDateTimeDefault: 'YYYY/MM/DD HH:mm:ss',

  // 日時表示の秒表示なしフォーマット
  displayFormatDateTimeNoSecond: 'YYYY/MM/DD HH:mm',

  // 文字列パースのデフォルトフォーマット
  // フォーマット文字列のため除外
  // cspell: disable
  parseFormatDefault: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  // cspell: enable

  // クイックチャット一覧用のデフォルトフォーマット
  quickchatListFormatDefault: 'HH:mm',

  // 文字列化のデフォルトフォーマット
  // フォーマット文字列のため除外
  // cspell: disable
  stringifyFormatDefault: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  // cspell: enable

  // タイムピッカー入力用のデフォルトフォーマット
  timePickerFormatDefault: 'HH:mm:ss',
};

export default DayjsFormats;
