import AttendanceService from '@/services/ui/AttendanceService';
import AuthService from '@/services/ui/AuthService';
import ChatService from '@/services/ui/ChatService';
import DashboardService from '@/services/ui/DashboardService';
import DepartmentMemberService from '@/services/ui/DepartmentMemberService';
import DepartmentService from '@/services/ui/DepartmentService';
import GoogleService from '@/services/ui/GoogleService';
import GroupMemberService from '@/services/ui/GroupMemberService';
import GroupService from '@/services/ui/GroupService';
import ImageService from '@/services/ui/ImageService';
import LoggerService from '@/services/ui/LoggerService';
import PhraseService from '@/services/ui/PhraseService';
import PositionService from '@/services/ui/PositionService';
import ProjectMemberService from '@/services/ui/ProjectMemberService';
import ProjectService from '@/services/ui/ProjectService';
import RelationshipService from '@/services/ui/RelationshipService';
import SearchService from '@/services/ui/SearchService';
import UserService from '@/services/ui/UserService';
import VoicexService from '@/services/ui/VoicexService';
import WebPushService from '@/services/ui/WebPushService';
import WorkspaceService from '@/services/ui/WorkspaceService';

const services = {
  attendance: AttendanceService,
  auth: AuthService,
  chat: ChatService,
  dashboard: DashboardService,
  department: DepartmentService,
  departmentMember: DepartmentMemberService,
  google: GoogleService,
  group: GroupService,
  groupMember: GroupMemberService,
  image: ImageService,
  logger: LoggerService,
  phrase: PhraseService,
  position: PositionService,
  project: ProjectService,
  projectMember: ProjectMemberService,
  relationship: RelationshipService,
  search: SearchService,
  user: UserService,
  voicex: VoicexService,
  webPush: WebPushService,
  workspace: WorkspaceService,
};

export default {
  get<K extends keyof typeof services>(name: K): typeof services[K] {
    return services[name];
  },
};
