import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export const INITIAL_SEARCH_CONDITIONS = {
  conditions: {
    projects: {
      ids: ['main'],
      operator: 0,
    },
  },
  operator: 0,
};

export default {
  // 指定された条件のメンバー情報を返す
  async memberSearch(conditions: Record<string, unknown>) {
    try {
      const response = await Service.post(`/membersearch/`, JSON.stringify(conditions), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('memberSearch#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('memberSearch#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = '存在しないユーザーです。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },
};
