import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  // 画像をアップロードする
  async uploadImage(
    workspaceId: string,
    kind: 'workspace' | 'user' | 'appli',
    file: File,
    options?: {
      width?: number;
      height?: number;
    }
  ) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('kind', kind);
    if (options) {
      formData.append('options', JSON.stringify(options));
    }

    try {
      const response = await Service.post(`/image/upload/${workspaceId}`, formData, {
        headers: generateHeaders('Bearer', {
          Accept: 'application/json',
          'content-type': 'multipart/form-data',
        }),
      });

      Log.debug('uploadImage#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('uploadImage#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },
};
