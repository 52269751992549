







import Vue from 'vue';
import { UICommonMapper } from '@/store/modules/ui/common';

export default Vue.extend({
  name: 'TheLoadingOverlay',

  computed: {
    ...UICommonMapper.mapState(['navigating']),
  },
});
