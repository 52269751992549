import _ from 'lodash';
import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';

// メンバーイベント（Googleカレンダースケジュール）
export type MemberEvent = {
  hangoutLink: string; // 会議URL（あれば会議中）
  eventId: string;
  summary: string; // スケジュール名（会議名）
  start: number | string; // timestamp or YYYY-MM-DD HH:mm:ss
  end: number | string; // timestamp or YYYY-MM-DD HH:mm:ss
};

// メンバー情報
export type Member = {
  workspaceId: string;
  userId: string;
  userName: string;
  kana: string;
  email: string;
  adminLevel: number;
  status: number;
  emotion: number;
  image: string;
  currentApp: {
    time: number;
    voiceX: boolean;
    appId: string;
    appName: string;
    appTitle: string;
    appImage: string;
    memberId: string;
    linkedId: string;
    outbound: boolean;
    startedAt: string;
    phoneNo: string;
  };
  eventList: MemberEvent[];
  chatUnread: number;
  position: string;
  voiceXPairingColor: number;
  attendanceFlag: number; // 出勤:1, 退勤:0
};

// メンバー情報の一部
export type PartialMember = Partial<Member>;

export type Relationship = PartialMember[];

class UIRelationshipState {
  // メンバーサークルでの関係度を持ったメンバー一覧
  memberRelationship: Relationship = [];

  // メインサークルでの関係度を持ったメンバー一覧
  relationship: Relationship = [];
}

class UIRelationshipGetters extends Getters<UIRelationshipState> {}

class UIRelationshipMutations extends Mutations<UIRelationshipState> {
  setMemberRelationship(payload: { relationship: Relationship }) {
    this.state.memberRelationship = payload.relationship;
  }

  setRelationship(payload: { relationship: Relationship }) {
    this.state.relationship = payload.relationship;
  }

  updateMember(payload: { member: PartialMember }) {
    const member = _.find(this.state.relationship, { userId: payload.member.userId });
    _.assignIn(member, payload.member);
  }
}

class UIRelationshipActions extends Actions<
  UIRelationshipState,
  UIRelationshipGetters,
  UIRelationshipMutations,
  UIRelationshipActions
> {
  setMemberRelationship(payload: { relationship: Relationship }) {
    this.mutations.setMemberRelationship(payload);
  }

  setRelationship(payload: { relationship: Relationship }) {
    this.mutations.setRelationship(payload);
  }

  updateMember(payload: { member: PartialMember }) {
    this.mutations.updateMember(payload);
  }
}

export const UIRelationship = new Module({
  actions: UIRelationshipActions,
  getters: UIRelationshipGetters,
  mutations: UIRelationshipMutations,
  state: UIRelationshipState,
});

export const UIRelationshipMapper = createMapper(UIRelationship);
