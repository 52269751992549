import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';

// 部署
export type Department = {
  departmentId: string;
  departmentName: string;
  regDate: string;
  workspaceId: string;
  _id: string;
};

class UIDepartmentState {
  // 部署一覧
  departmentList: Department[] = [];
}

class UIDepartmentGetters extends Getters<UIDepartmentState> {}

class UIDepartmentMutations extends Mutations<UIDepartmentState> {
  setDepartmentList(payload: { departments: Department[] }) {
    this.state.departmentList = payload.departments;
  }
}

class UIDepartmentActions extends Actions<
  UIDepartmentState,
  UIDepartmentGetters,
  UIDepartmentMutations,
  UIDepartmentActions
> {
  // 部署情報をセットする
  setDepartmentList(payload: { departments: Department[] }) {
    this.mutations.setDepartmentList(payload);
  }
}

export const UIDepartment = new Module({
  actions: UIDepartmentActions,
  getters: UIDepartmentGetters,
  mutations: UIDepartmentMutations,
  state: UIDepartmentState,
});

export const UIDepartmentMapper = createMapper(UIDepartment);
