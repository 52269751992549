import _ from 'lodash';
import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';

// TimeData内の打刻情報の形式
export type TimeDataItem = {
  ctime?: string; // YYYY-MM-DD HH:mm:ss
  reason: string;
  status: number; // 0: 有効, -1: 無効（削除）
  time: string; // YYYY-MM-DD HH:mm:ss
  regDate: string; // '2023-07-12T05:57:08.235Z'
  updDate: string; // '2023-07-12T05:57:08.235Z'
};

// RecordItemのtime要素の形式
export type TimeData = {
  punchIn: TimeDataItem;
  punchOut: TimeDataItem;
};

// DataTableで扱う打刻の形式
export type RecordItem = {
  date: string; // "2023-02-01"
  time: TimeData;
};

// EditingData内の詳細情報
export type EditingDataItem = {
  origin: string; // YYYY-MM-DD HH:mm:ss (修正前)
  newest: string; // YYYY-MM-DD HH:mm:ss (修正後)
  status: number; // 0: 有効, -1: 無効（削除）
  reason: string; // 最新修正理由
  oldestRegDate: string; // '2023-07-12T05:57:08.235Z'
  newestUpdDate: string; // '2023-07-12T05:57:08.235Z'
};

// 修正ポップアップ表示用データ形式
export type EditingData = {
  date: string; // "2023-02-01"
  time: {
    punchIn: EditingDataItem;
    punchOut: EditingDataItem;
  };
};

// RecordItemの初期値
export const InitialRecordItem: Readonly<RecordItem> = Object.freeze({
  date: '',
  time: {
    punchIn: {
      ctime: '',
      reason: '',
      regDate: '',
      status: 0,
      time: '',
      updDate: '',
    },
    punchOut: {
      ctime: '',
      reason: '',
      regDate: '',
      status: 0,
      time: '',
      updDate: '',
    },
  },
});

// EditingItemの初期値
export const InitialEditingItem: Readonly<EditingData> = Object.freeze({
  date: '',
  time: {
    punchIn: {
      // EditingDataItem定義順
      /* eslint-disable vue/sort-keys */
      origin: '',
      newest: '',
      status: 0,
      reason: '',
      oldestRegDate: '',
      newestUpdDate: '',
      /* eslint-enable vue/sort-keys */
    },
    punchOut: {
      // 定義順
      /* eslint-disable vue/sort-keys */
      origin: '',
      newest: '',
      status: 0,
      reason: '',
      oldestRegDate: '',
      newestUpdDate: '',
      /* eslint-enable vue/sort-keys */
    },
  },
});

class UIAttendanceState {
  // 編集する出勤日情報
  editingItem: EditingData = _.cloneDeep(InitialEditingItem);

  // 更新完了
  updateComplete = false;
}

class UIAttendanceGetters extends Getters<UIAttendanceState> {}

class UIAttendanceMutations extends Mutations<UIAttendanceState> {
  clearEditingItem() {
    this.state.editingItem = _.cloneDeep(InitialEditingItem);
  }

  setEditingItem(payload: { item: EditingData }) {
    this.state.editingItem = payload.item;
  }

  setUpdateComplete(payload: { param: boolean }) {
    this.state.updateComplete = payload.param;
  }
}

class UIAttendanceActions extends Actions<
  UIAttendanceState,
  UIAttendanceGetters,
  UIAttendanceMutations,
  UIAttendanceActions
> {
  // 編集する出勤日情報をクリアする
  clearEditingItem() {
    this.mutations.clearEditingItem();
  }

  // 更新完了フラグをリセットする
  resetUpdateComplete() {
    this.mutations.setUpdateComplete({ param: false });
  }

  // 編集する出勤日情報をセットする
  setEditingItem(payload: { item: EditingData }) {
    this.mutations.setEditingItem(payload);
  }

  // 更新完了フラグをセットする
  setUpdateComplete() {
    this.mutations.setUpdateComplete({ param: true });
  }
}

export const UIAttendance = new Module({
  actions: UIAttendanceActions,
  getters: UIAttendanceGetters,
  mutations: UIAttendanceMutations,
  state: UIAttendanceState,
});

export const UIAttendanceMapper = createMapper(UIAttendance);
