import 'github-markdown-css/github-markdown.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ja from 'vuetify/src/locale/ja';
import '@/scss/styles.scss';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  lang: {
    current: 'ja',
    locales: { ja },
  },
  theme: {
    themes: {
      dark: {
        accent: '#e1a032',
        anchor: '#1976d2',
        primary: '#6357fc',
        'white-lilac': '#f6f8fc',
      },
      light: {
        accent: '#e1a032',
        anchor: '#1976d2',
        error: 'f29a9a',
        info: '#adcff8',
        primary: '#6357fc',
        success: '#85c77c',
        warning: '#f2bd6a',
        'white-lilac': '#f6f8fc',
      },
    },
  },
});
