import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  // グループメンバーを登録（追加）する
  async createGroupMember(params: {
    workspaceId: string;
    userId: string;
    groupId: string;
    memberId: string;
  }) {
    try {
      const response = await Service.post(`/groupmember/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('createGroupMember#Response', response);

      return response.data;
    } catch (error) {
      Log.error('createGroupMember#Error', error);

      throw error;
    }
  },

  // 指定されたグループのグループメンバー一覧を返す
  async getGroupMemberList(workspaceId: string, userId: string, groupId: string) {
    try {
      const response = await Service.get(`/groupmember/list/${workspaceId}/${userId}/${groupId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getGroupMemberList#Response', response);

      return response.data;
    } catch (error) {
      Log.error('getGroupMemberList#Error', error);

      throw error;
    }
  },

  // グループメンバーを登録（入れ替え）する
  async replaceGroupMember(params: {
    workspaceId: string;
    userId: string;
    groupId: string;
    memberIds: string[];
  }) {
    try {
      const response = await Service.post(`/groupmember/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('replaceGroupMember#Response', response);

      return response.data;
    } catch (error) {
      Log.error('replaceGroupMember#Error', error);

      throw error;
    }
  },
};
