import dayjs from 'dayjs';
import _ from 'lodash';
import { helpers } from 'vuelidate/lib/validators';

const Validators = {
  // 打刻時刻形式として有効かどうか、正しい範囲に収まっているかをチェックする。
  // 24時間表記のHHmmで先頭に*が付いても良い。24時以降も受け付ける。
  // from,to は HHmm の形式で指定する。
  // * が付くとき(前日付け)、from以降、toより前 であること。
  // * が付かないとき(本日および翌日)、from以降、to+24時間より前 であること。
  attendanceTime: (from: string, to: string) =>
    helpers.withParams({ type: 'attendanceTime' }, (value: string) => {
      if (!value || typeof value !== 'string') {
        return true; // 空文字を許可します（validとします）
      }

      // 先頭に * が付いているかどうかをチェックします
      const isPreviousDay = value.startsWith('*');

      // 先頭に * が付いている場合は * を除去してから時間と分を取得します
      const timeStr = isPreviousDay ? value.slice(1) : value;
      const hours = parseInt(timeStr.slice(0, 2), 10);
      const minutes = parseInt(timeStr.slice(2, 4), 10);

      if (timeStr.length !== 4) {
        return false;
      }

      // 分が59を超えている場合は不正な値とします
      if (minutes > 59) {
        return false;
      }

      // fromとtoの時刻をパースします
      const fromHours = parseInt(from.slice(0, 2), 10);
      const fromMinutes = parseInt(from.slice(2, 4), 10);
      const toHours = isPreviousDay
        ? parseInt(to.slice(0, 2), 10)
        : parseInt(to.slice(0, 2), 10) + 24;
      const toMinutes = parseInt(to.slice(2, 4), 10);

      // '3030' のようなケースを考慮して、fromとtoの時刻を正規化します
      const normalizedFrom = fromHours * 60 + fromMinutes;
      const normalizedTo = toHours * 60 + toMinutes;

      // 入力された時刻を正規化します
      const normalizedValue = hours * 60 + minutes;

      // 時がfrom以上かつto以下であり、分が59以下であればvalidとします
      if (normalizedValue >= normalizedFrom && normalizedValue <= normalizedTo) {
        return true;
      }

      return false;
    }),
  // タブ文字を含んでいないかをチェックする
  containsNoTabs: helpers.withParams(
    { type: 'containsNoTabs' },
    (value: string) => !helpers.req(value) || /^[^\t]*$/.test(value)
  ),
  // 全角カナのみを含むかどうかをチェックする
  containsOnlyFullWidthKatakana: helpers.withParams(
    { type: 'containsOnlyFullWidthKatakana' },
    (value) => !helpers.req(value) || /^[ァ-ヶー]+$/.test(value)
  ),
  // 全角カナとスペースのみを含むかどうかをチェックする
  containsOnlyFullWidthKatakanaAndSpace: helpers.withParams(
    { type: 'containsOnlyFullWidthKatakanaAndSpace' },
    // 全角スペースを許容するために許可
    // eslint-disable-next-line no-irregular-whitespace
    (value) => !helpers.req(value) || /^[ァ-ヶー 　]+$/.test(value)
  ),
  // 発報置き換え文字列として利用できる文字列かどうかをチェックをする
  dialReplacement: helpers.withParams(
    { type: 'dialReplacement' },
    (value) => !helpers.req(value) || /^[a-zA-Z][a-zA-Z0-9_-]*$/.test(value)
  ),
  // 指定区切りで分割した要素がすべてメールアドレスかをチェックする(デフォルト区切り文字は,(カンマ))
  emailBulk: (separator = ',') =>
    helpers.withParams({ separator, type: 'emailBulk' }, (value: string) => {
      // vuelidate builtin validator email の regex を利用
      // eslint-disable-next-line no-control-regex
      const regexp = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
      let valid = true;
      _.forEach(_.split(value, separator), (item) => {
        if (!helpers.req(value) || !regexp.test(_.trim(item))) {
          valid = false;
        }
      });
      return valid;
    }),
  // 指定文字数かどうかをチェックする
  fixedLength: (length: number) =>
    helpers.withParams({ length, type: 'fixedLength' }, (value: string) => value.length === length),
  // 発信番号として設定できる電話番号かどうかをチェックする
  forwardExtPhone: helpers.withParams(
    { type: 'forwardExtPhone' },
    (value) => !helpers.req(value) || /^0[1-9]\d{8,13}$/.test(value)
  ),
  // 指定区切りで分割した要素がIPアドレスとして正しい形式かどうかをチェックする
  ipAddressDividedBy: (separator = '\n') =>
    helpers.withParams({ separator, type: 'ipAddressDividedBy' }, (value: string) => {
      const arrayIpAddresses = _.map(_.split(value, separator), _.trim);

      return (
        !helpers.req(value) ||
        !_.some(arrayIpAddresses, (item) => {
          const regIpRangeFormat = new RegExp(
            /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])-((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$/
          );
          const regIpMaskFormat = new RegExp(
            /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\/(3[0-2]|[1-2]?[0-9])$/
          );
          const regIpAsteriskFormat = new RegExp(
            /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9]|[*])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9]|[*])$/
          );

          // 空行は無視
          if (_.isEmpty(item)) {
            return false;
          }
          // 文字列に-が含まれている場合、-で分割して標準のIPアドレスフォーマットで確認
          if (item.match(/-/)) {
            return !regIpRangeFormat.test(item);
          }
          // 文字列に/が含まれている場合、標準のIPアドレスフォーマット/サブネットで確認
          if (item.match(/\//)) {
            return !regIpMaskFormat.test(item);
          }
          // それ以外では標準のIPアドレスフォーマットおよび*で確認
          return !regIpAsteriskFormat.test(item);
        })
      );
    }),
  // 指定区切りで分割した要素が指定件数以下かをチェックする(デフォルト区切り文字は,(カンマ))
  // ignoreEmptyItem=trueの場合は空要素をカウントしない(デフォルトは空要素を無視しない)
  maxCountDividedBy: (max: number, separator = ',', ignoreEmptyItem = false) =>
    helpers.withParams(
      { ignoreEmptyItem, max, separator, type: 'maxCountDividedBy' },
      (value: string) => {
        const splitItems = ignoreEmptyItem
          ? _.remove(_.split(value, separator), (item) => !_.isEmpty(item))
          : _.split(value, separator);
        return !helpers.req(value) || _.size(splitItems) <= max;
      }
    ),
  // 指定区切りで分割したメールアドレスが指定文字数以下かをチェックする(デフォルト区切り文字は,(カンマ))
  maxLengthEmailBulk: (max: number, separator = ',') =>
    helpers.withParams({ max, separator, type: 'maxLengthEmailBulk' }, (value: string) => {
      const arrayMails = _.map(_.split(value, separator), _.trim);
      return !helpers.req(value) || !_.some(arrayMails, (item) => item.length > max);
    }),
  // 指定の文字列で始まっていないかをチェックする
  notStartWith: (ngWords: string[]) =>
    helpers.withParams(
      { ngWords, type: 'notStartWith' },
      (value: string) =>
        !helpers.req(value) || !_.some(ngWords, (ngWord) => _.startsWith(value, ngWord))
    ),
  // パスワードの複雑性 (半角英数字 (英字数字記号大文字小文字をそれぞれ含む) 8文字以上32文字以下) を満たすかどうかをチェックする
  passwordComplexity: helpers.withParams(
    { type: 'passwordComplexity' },
    (value: string) =>
      !helpers.req(value) ||
      /^(?=.*[A-Z])(?=.*?\d)(?=.*[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`])[a-zA-Z\d=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]{8,32}$/.test(
        value
      )
  ),
  // 配列内で一意の値となっているかどうかをチェックする
  // 型が不明のためanyを許容
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unique: (listLocator: string | ((vm: any, parentVm?: Vue) => any), key?: string) =>
    helpers.withParams(
      { key, listLocator, type: 'unique' },
      // 型が不明のためanyを許容
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function validate(this: any, value: any, parentVm: Vue) {
        const list = helpers.ref(listLocator, this, parentVm);

        let firstIndex;
        let lastIndex;

        if (_.isUndefined(key)) {
          firstIndex = _.indexOf(list, value);
          lastIndex = _.lastIndexOf(list, value);
        } else {
          // リストの要素は不定のため許容
          /* eslint-disable @typescript-eslint/no-explicit-any */
          firstIndex = _.findIndex(list, (item: { [key: string]: any }) => item[key] === value);
          lastIndex = _.findLastIndex(list, (item: { [key: string]: any }) => item[key] === value);
          /* eslint-enable @typescript-eslint/no-explicit-any */
        }

        return !helpers.req(value) || firstIndex === lastIndex;
      }
    ),
  // 指定区切りで分割したメールアドレスが重複していないかをチェックする(デフォルト区切り文字は,(カンマ))
  uniqueEmailBulk: (separator = ',') =>
    helpers.withParams({ separator, type: 'uniqueEmailBulk' }, (value: string) => {
      const arrayMails = _.map(_.split(value, separator), _.trim);
      const arrayMailsUnique = _.uniq(arrayMails);

      return !helpers.req(value) || arrayMails.length === arrayMailsUnique.length;
    }),
  // 指定区切りで分割した要素が重複していないかをチェックする(デフォルト区切り文字は,(カンマ))
  // ignoreEmptyItem=trueの場合は空要素をカウントしない(デフォルトは空要素を無視しない)
  uniqueIPAddressBulk: (separator = ',', ignoreEmptyItem = false) =>
    helpers.withParams(
      { ignoreEmptyItem, separator, type: 'uniqueIPAddressBulk' },
      (value: string) => {
        const splitItems = ignoreEmptyItem
          ? _.remove(_.split(value, separator), (item) => !_.isEmpty(item))
          : _.split(value, separator);
        const arrayItems = _.map(splitItems, _.trim);
        const arrayItemsUnique = _.uniq(arrayItems);

        return !helpers.req(value) || arrayItems.length === arrayItemsUnique.length;
      }
    ),
  // 日時範囲が正しいかをチェックする
  validDateRange: (fromKeyName = 'from', toKeyName = 'to') =>
    helpers.withParams(
      { fromKeyName, toKeyName, type: 'validDateRange' },
      (value) =>
        !helpers.req(value) ||
        _.isEmpty(value[fromKeyName]) ||
        _.isEmpty(value[toKeyName]) ||
        dayjs(value[fromKeyName]).isBefore(value[toKeyName])
    ),
};

export default Validators;
