import _ from 'lodash';
import { ATTENDANCE_KIND } from '@/resources/defines';
import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  // 打刻する
  async clocking(params: {
    workspaceId: string;
    userId: string;
    date: string; // YYYYMMDD
    kind: typeof ATTENDANCE_KIND[number]['value'];
  }) {
    try {
      const response = await Service.post(`/attendance/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('clocking#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('clocking#Error', error);

      switch (error.status) {
        case 400:
          switch (error.errCode) {
            case 102:
              error.message = '退勤打刻と同じか遅い日時に打刻しています。';
              break;
            case 103:
              error.message = '出勤打刻と同じか早い日時に打刻しています。';
              break;
            case 104:
              error.message = '前日の打刻と同じか早い日時に打刻しています。';
              break;
            case 105:
              error.message = '翌日の打刻と同じか遅い日時に打刻しています。';
              break;
            default:
              error.message = '打刻日時で予期しないエラーが発生しました。';
          }
          break;
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = '存在しないユーザーです。';
          break;
        case 409:
          switch (params.kind) {
            case 1:
              error.message = '出勤打刻が重複しています。<br>1日1回のみ打刻可能です。';
              break;
            case 2:
              error.message = '退勤打刻が重複しています。<br>1日1回のみ打刻可能です。';
              break;
            default:
              error.message = '同じ日に打刻が重複しているます。';
          }
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 対象日・打刻種別を修正する
  async correctingDateKind(
    workspaceId: string,
    userId: string,
    date: string,
    kind: typeof ATTENDANCE_KIND[number]['value'],
    params: {
      date: string;
      kind: typeof ATTENDANCE_KIND[number]['value'];
      reason: string;
    }
  ) {
    try {
      const response = await Service.put(
        `/attendance/move/${workspaceId}/${userId}/${date}/${kind}`,
        JSON.stringify(params),
        {
          headers: generateHeaders('Bearer', { Accept: 'application/json' }),
        }
      );

      Log.debug('correctingDateKind#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('correctingDateKind#Error', error);

      switch (error.status) {
        case 400:
          switch (error.errCode) {
            case 101:
              error.message = '修正日時が有効な日時ではありません。';
              break;
            case 102:
              error.message = '退勤打刻よりも遅い日時が指定されています。';
              break;
            case 103:
              error.message = '出勤打刻よりも早い日時が指定されています。';
              break;
            case 104:
              error.message = '前日の打刻と同じか早い日時に打刻しています。';
              break;
            case 105:
              error.message = '翌日の打刻と同じか遅い日時に打刻しています。';
              break;
            default:
              error.message = '日時の指定で予期しないエラーが発生しました。';
          }
          break;
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = '存在しないユーザーです。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 打刻時間を修正する
  async correctingTime(
    workspaceId: string,
    userId: string,
    date: string, // YYYYMMDD
    kind: typeof ATTENDANCE_KIND[number]['value'],
    params: {
      time: string; // YYYY-MM-DD HH:mm:ss
      reason: string;
    }
  ) {
    try {
      const response = await Service.put(
        `/attendance/${workspaceId}/${userId}/${date}/${kind}`,
        JSON.stringify(params),
        {
          headers: generateHeaders('Bearer', { Accept: 'application/json' }),
        }
      );

      Log.debug('correctingTime#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('correctingTime#Error', error);

      switch (error.status) {
        case 400:
          switch (error.errCode) {
            case 101:
              error.message = '修正日時が有効な日時ではありません。';
              break;
            case 102:
              error.message = '退勤打刻と同じか遅い日時が指定されています。';
              break;
            case 103:
              error.message = '出勤打刻と同じか早い日時が指定されています。';
              break;
            case 104:
              error.message = '前日の打刻と同じか早い日時に打刻しています。';
              break;
            case 105:
              error.message = '翌日の打刻と同じか遅い日時に打刻しています。';
              break;
            case 106:
              error.message = '同じ日時の打刻が指定されています。';
              break;
            default:
              error.message = '日時の指定で予期しないエラーが発生しました。';
          }
          break;
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = '存在しないユーザーです。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 打刻時間を削除する
  async deleting(
    workspaceId: string,
    userId: string,
    date: string,
    kind: typeof ATTENDANCE_KIND[number]['value'],
    params: {
      reason: string;
    }
  ) {
    try {
      const postParams = _.assign({}, params, { delete: 1 });

      const response = await Service.put(
        `/attendance/${workspaceId}/${userId}/${date}/${kind}`,
        JSON.stringify(postParams),
        {
          headers: generateHeaders('Bearer', { Accept: 'application/json' }),
        }
      );

      Log.debug('deleting#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('deleting#Error', error);

      switch (error.status) {
        case 400:
          error.message = '正しい打刻情報が送られていません。';
          break;
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          switch (error.errCode) {
            case 101:
              error.message = '削除対象のユーザーが存在しません。';
              break;
            case 102:
              error.message = '削除対象の打刻情報がありません。';
              break;
            default:
              error.message = '削除対象の予期しないエラーが発生しました。';
          }
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 勤怠情報一覧を取得する
  async getList(params: {
    workspaceId: string;
    term: {
      kind: 'month' | 'day';
      date: string; // YYYYMMDD
    };
    target: {
      userIds: string[];
    };
  }) {
    try {
      const response = await Service.post(`/attendance/list/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getList#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getList#Error', error);

      switch (error.status) {
        case 400:
          error.message = '正しい打刻情報が送られていません。';
          break;
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 全ての勤怠情報一覧を取得する
  async getListAll(params: {
    workspaceId: string;
    term: {
      kind: 'month' | 'day';
      date: string; // YYYYMMDD
    };
    target: {
      userIds: string[];
    };
  }) {
    try {
      const response = await Service.post(`/attendance/list/all`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getListAll#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getListAll#Error', error);

      switch (error.status) {
        case 400:
          error.message = '正しい打刻情報が送られていません。';
          break;
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },
};
