// バリデーションメッセージ一覧
// _付きでないものはVuelidateのビルトインバリデータかValidators.ts内の独自定義バリデータのtypeと一致するため、generateMessage.tsの処理でそのバリデータのエラーメッセージとして使用される
// _付きのものはバリデータと紐づかないため、VuelidateヘルパーのwithParamsでキー名をtypeとして定義するか、メッセージ文字列を$$generateMessageメソッドの引数として渡すかする必要がある
const ValidationMessages = {
  _maxLengthCount: ({ max }: { max: number }) => `${max}件以内で入力してください。`,
  _maxLengthCountSelect: ({ max }: { max: number }) => `${max}件以内で選択してください。`,
  _minLengthCount: ({ min }: { min: number }) => `${min}件以上入力してください。`,
  _minLengthCountSelect: ({ min }: { min: number }) => `${min}件以上選択してください。`,
  _requiredLeastOne: '1件以上入力してください。',
  _requiredLeastOneIf: '1件以上入力してください。',
  _requiredLeastOneSelect: '1件以上選択してください。',
  _requiredLeastOneSelectIf: '1件以上選択してください。',
  _requiredLeastOneSelectUnless: '1件以上選択してください。',
  _requiredLeastOneUnless: '1件以上入力してください。',
  _requiredSelect: '必ず選択してください。',
  _requiredSelectIf: '必ず選択してください。',
  _requiredSelectUnless: '必ず選択してください。',
  alpha: '使用できる文字は半角英字のみです。',
  alphaNum: '使用できる文字は半角英数字のみです。',
  attendanceTime: '正しい時刻を入力してください。',
  between: ({ min, max }: { min: number | Date; max: number | Date }) =>
    `${min}以上${max}以下の数値で入力してください。`,
  containsNoTabs: '不正な文字が含まれています。',
  containsOnlyFullWidthKatakana: '使用できる文字は全角カタカナのみです。',
  containsOnlyFullWidthKatakanaAndSpace: '使用できる文字は全角カタカナとスペースのみです。',
  decimal: '10進数で入力してください。',
  dialReplacement: '使用できる文字は半角英数字と-_のみ（先頭は英字のみ）です。',
  email: '正しいメールアドレス形式で入力してください。',
  emailBulk: '正しいメールアドレス形式で入力してください。',
  fixedLength: ({ length }: { length: number }) => `${length}文字で入力してください。`,
  forwardExtPhone: '無効な電話番号です。',
  integer: '整数で入力してください。',
  ipAddress: '無効なIPアドレスです。',
  ipAddressDividedBy: '無効なIPアドレスです。',
  macAddress: '無効なMACアドレスです。',
  maxCountDividedBy: ({ max }: { max: number }) => `${max}件以内で入力してください。`,
  maxEmailLength: ({ max }: { max: number }) =>
    `各メールアドレスは${max}文字以下で入力してください。`,
  maxLength: ({ max }: { max: number }) => `${max}文字以下で入力してください。`,
  maxLengthEmailBulk: ({ max }: { max: number }) =>
    `各メールアドレスは${max}文字以下で入力してください。`,
  maxValue: ({ max }: { max: number | Date }) => `${max}以下の数値で入力してください。`,
  minLength: ({ min }: { min: number }) => `${min}文字以上入力してください。`,
  minValue: ({ min }: { min: number | Date }) => `${min}以上の数値で入力してください。`,
  notStartWith: '使用できない文字列で始まっています。',
  numeric: '数字のみ入力可能です。',
  passwordComplexity: '半角英字大小文字、数字、記号を含む必要があります。',
  required: '必ず入力してください。',
  requiredIf: '必ず入力してください。',
  requiredUnless: '必ず入力してください。',
  sameAs: `一致しません。`,
  unique: '重複しています。',
  uniqueEmail: 'メールアドレスが重複しています。',
  uniqueEmailBulk: 'メールアドレスが重複しています。',
  uniqueIPAddressBulk: 'IPアドレスが重複しています。',
  url: '無効なURLです。',
  validDateRange: '選択された期間が不正です。',
};

export default ValidationMessages;
