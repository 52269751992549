import dayjs from 'dayjs';
import Vue from 'vue';
import Log from '../Logger/Log';

// Dayjsを表示用の日付文字列に変換 (日付表示のデフォルトフォーマット使用)
// dayjsObject: 変換したいDayjsオブジェクト
const formatToDateString = function formatToDateString(this: Vue, dayjsObject: dayjs.Dayjs) {
  if (this.$$dayjs.isDayjs(dayjsObject)) {
    return dayjsObject.format(this.$$dayjsFormats.displayFormatDateDefault);
  }

  Log.debug('値がDayjsではありません。', dayjsObject);

  return '';
};

export default formatToDateString;
