import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  async registerLog(
    authenticated: boolean,
    params: {
      level?: 'debug' | 'info' | 'warn' | 'error';
      message: { [key: string]: unknown };
    }
  ) {
    let apiName: string;

    if (authenticated) {
      apiName = '/logger-wauth';
    } else {
      apiName = '/logger';
    }

    try {
      const response = await Service.post(apiName, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('registerLog#Response', response);
    } catch (error: any) {
      Log.error('registerLog#Error', error);

      throw error;
    }
  },
};
