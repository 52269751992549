import _Vue from 'vue';
import Log from './Log';

// ログ出力プラグイン
// process.env.NODE_ENVが'production'でない場合のみログ出力する機能を追加する
const Logger = {
  // Vue: Vueコントラクタ
  install(Vue: typeof _Vue) {
    Vue.$$log = Log;
    Vue.prototype.$$log = Log;
  },
};

export default Logger;
