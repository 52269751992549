import dayjs from 'dayjs';
import Vue from 'vue';
import Log from '../Logger/Log';

// Dayjsを文字列に変換 (文字列化のデフォルトフォーマット使用)
// dayjs: 変換したいDayjsオブジェクト
const stringify = function stringify(this: Vue, dayjsObject: dayjs.Dayjs) {
  if (this.$$dayjs.isDayjs(dayjsObject)) {
    return dayjsObject.format(this.$$dayjsFormats.stringifyFormatDefault);
  }

  Log.debug('値がDayjsではありません。', dayjsObject);

  return '';
};

export default stringify;
