import settingsDevelopment from '@/settings/settingsDevelopment';
import settingsProduction from '@/settings/settingsProduction';
import settingsStaging from '@/settings/settingsStaging';
import type {} from 'vue-toastification';

export type ShineConnectSettings = {
  messageCloseWaitTime: number;
  apiEndpoint: string;
  apiKey: string;
  webSocket: {
    url: string;
    cookieDomain: string;
    options: {
      connectionTimeout: number;
      maxReconnectAttempts: number;
      reconnectInterval: number;
      debug?: boolean;
    };
  };
  agent: {
    url: {
      win: string;
      mac: string;
    };
  };
  attendance: {
    atdBorderTime: string;
    atdLimitTime: string;
  };
  toast: {
    instanceOptions: Record<string, unknown>;
    messageOptions: Record<string, unknown>;
  };
  deployTargetInPageTitle: string;
  deployTargetName: string;
  helpButtonItems: {
    [pageId: string]: {
      icon: string;
      title: string;
      url: string;
    }[];
  };
  defaultTimezone: string;
  externalLinkURLs: {
    browserPhone: string;
    loginIssue: string;
    news: string;
    privacyPolicy: string;
  };
  mainDomainURL: string;
  interfaceAPIEndpoint: string;
  strapiAPIEndpoint: string;
  strapiImageBaseURL: string;
};

let settings: ShineConnectSettings;

if (process.env.VUE_APP_SETTINGS_TYPE === 'development') {
  settings = settingsDevelopment;
} else if (process.env.VUE_APP_SETTINGS_TYPE === 'staging') {
  settings = settingsStaging;
} else {
  settings = settingsProduction;
}

export default Object.freeze(settings);
