import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  // プロジェクトメンバーを登録する
  async createProjectMember(params: { workspaceId: string; projectId: string; memberId: string }) {
    try {
      const response = await Service.post(`/projectmember/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('createProjectMember#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('createProjectMember#Error', error);

      throw error;
    }
  },

  // 指定されたプロジェクトのメンバー削除
  async deleteProjectMember(workspaceId: string, projectId: string, memberId: string) {
    try {
      const response = await Service.delete(
        `/projectmember/${workspaceId}/${projectId}/${memberId}`,
        {
          headers: generateHeaders('Bearer', { Accept: 'application/json' }),
        }
      );

      Log.debug('deleteProjectMember#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('deleteProjectMember#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = 'プロジェクトメンバーが見つかりません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 指定されたプロジェクトのメンバー一覧を返す
  async getProjectMemberList(workspaceId: string, projectId: string) {
    try {
      const response = await Service.get(`/projectmember/list/${workspaceId}/${projectId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getProjectMemberList#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getProjectMemberList#Error', error);

      throw error;
    }
  },
};
