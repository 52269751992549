import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { createStore, Module } from 'vuex-smart-module';
import { DomainAuth } from '@/store/modules/domain/auth';
import { DomainStrapiInfo } from '@/store/modules/domainStrapi/info';
import { UIAttendance } from '@/store/modules/ui/attendance';
import { UIChat } from '@/store/modules/ui/chat';
import { UICommon } from '@/store/modules/ui/common';
import { UIDepartment } from '@/store/modules/ui/department';
// import { UIGoogleCalendar } from '@/store/modules/ui/googlecalendar'
import { UIGroup } from '@/store/modules/ui/group';
import { UIInfo } from '@/store/modules/ui/info';
import { UIMemberFilter } from '@/store/modules/ui/memberFilter';
import { UINotification } from '@/store/modules/ui/notification';
import { UIPasswordReset } from '@/store/modules/ui/passwordReset';
import { UIPosition } from '@/store/modules/ui/position';
import { UIProject } from '@/store/modules/ui/project';
import { UIRelationship } from '@/store/modules/ui/relationship';
import { UIWorkspace } from '@/store/modules/ui/workspace';

Vue.use(Vuex);

const root = new Module({
  modules: {
    domain: new Module({
      modules: {
        auth: DomainAuth,
      },
    }),
    domainStrapi: new Module({
      modules: {
        info: DomainStrapiInfo,
      },
    }),
    ui: new Module({
      modules: {
        attendance: UIAttendance,
        chat: UIChat,
        common: UICommon,
        department: UIDepartment,
        // googlecalendar: UIGoogleCalendar,
        group: UIGroup,
        info: UIInfo,
        memberFilter: UIMemberFilter,
        notification: UINotification,
        passwordReset: UIPasswordReset,
        position: UIPosition,
        project: UIProject,
        relationship: UIRelationship,
        workspace: UIWorkspace,
      },
    }),
  },
});

const store = createStore(root, {
  plugins: [
    createPersistedState({
      key: 'scAuth',
      paths: ['domain.auth.token'],
      storage: sessionStorage,
    }),
    createPersistedState({
      key: 'scDomain',
      paths: ['domain.auth.domain'],
      storage: sessionStorage,
    }),
    createPersistedState({
      key: 'scS',
      paths: ['domain.auth.token'],
      storage: sessionStorage,
    }),
    createPersistedState({
      key: 'scUserAttrs',
      paths: ['domain.auth.userAttributes'],
      storage: sessionStorage,
    }),
    createPersistedState({
      key: 'scUserStatus',
      paths: ['domain.auth.userStatus'],
      storage: sessionStorage,
    }),
    createPersistedState({
      key: 'passwordResetMail',
      paths: ['ui.passwordReset.passwordResetMailAddress'],
      storage: sessionStorage,
    }),
    createPersistedState({
      key: 'editingItem',
      paths: ['ui.attendance.editingItem'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'phraseList',
      paths: ['ui.chat.phraseList'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'quickChatMessages',
      paths: ['ui.chat.quickChatMessages'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'quickChatReplyChatId',
      paths: ['ui.chat.quickChatReplyChatId'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'quickChatReplyUserId',
      paths: ['ui.chat.quickChatReplyUserId'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'editGroupId',
      paths: ['ui.common.editGroupId'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'greeting',
      paths: ['ui.common.greeting'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'memberCircleUserId',
      paths: ['ui.common.memberCircleUserId'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'message',
      paths: ['ui.common.message'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'navigating',
      paths: ['ui.common.navigating'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'popupUserId',
      paths: ['ui.common.popupUserId'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'profileUserId',
      paths: ['ui.common.profileUserId'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'showedAttendanceBookPopup',
      paths: ['ui.common.showedAttendanceBookPopup'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'showedAttendanceEditPopup',
      paths: ['ui.common.showedAttendanceEditPopup'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'showedFilterPopup',
      paths: ['ui.common.showedFilterPopup'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'showedGreetingPopup',
      paths: ['ui.common.showedGreetingPopup'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'showedGroupEditorPopup',
      paths: ['ui.common.showedGroupEditorPopup'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'showedGroupListPopup',
      paths: ['ui.common.showedGroupListPopup'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'showedGroupListPopup',
      paths: ['ui.common.showedGroupListPopup'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'showedMemberCircle',
      paths: ['ui.common.showedMemberCircle'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'showedMemberListPopup',
      paths: ['ui.common.showedMemberListPopup'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'showedMemberPopup',
      paths: ['ui.common.showedMemberPopup'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'showedNavMenu',
      paths: ['ui.common.showedNavigationMenu'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'showedPopupBackgroundLayer',
      paths: ['ui.common.showedPopupBackgroundLayer'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'showedProfilePopup',
      paths: ['ui.common.showedProfilePopup'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'showedQuickChatPopup',
      paths: ['ui.common.showedQuickChatPopup'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'showedQuickChatListPopup',
      paths: ['ui.common.showedQuickChatListPopup'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'showedQuickChatReplyPopup',
      paths: ['ui.common.showedQuickChatReplyPopup'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'showedUserMenu',
      paths: ['ui.common.showedUserMenu'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'showedWorkspacePopup',
      paths: ['ui.common.showedWorkspacePopup'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'showedWebPushRecommendPopup',
      paths: ['ui.common.showedWebPushRecommendPopup'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'userList',
      paths: ['ui.common.userList'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'zoom',
      paths: ['ui.common.zoom'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'groupList',
      paths: ['ui.group.groupList'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'positionList',
      paths: ['ui.position.positionList'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'projectList',
      paths: ['ui.position.projectList'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'memberRelationship',
      paths: ['ui.relationship.memberRelationship'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'relationship',
      paths: ['ui.relationship.relationship'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'readInfoIds',
      paths: ['ui.info.readInfoIds'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'filter',
      paths: ['ui.memberFilter.filter'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'filterItems',
      paths: ['ui.memberFilter.filterItems'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'filtered',
      paths: ['ui.memberFilter.filtered'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'memberList',
      paths: ['ui.memberFilter.memberList'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'order',
      paths: ['ui.memberFilter.order'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'webPushToken',
      paths: ['ui.notification.webPushToken'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'workspace',
      paths: ['ui.workspace.workspace'],
      storage: localStorage,
    }),
  ],
  strict: process.env.NODE_ENV !== 'production',
});

export default store;
