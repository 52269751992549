import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  // GoogleカレンダーWatchイベント(チャンネル)を解除する
  async deleteCalendarLink(workspaceId: string, userId: string) {
    try {
      const response = await Service.delete(`/google/calendar/channel/${workspaceId}/${userId}/`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('deleteCalendarLink#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('deleteCalendarLink#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = '存在しないユーザーです。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました';
      }

      throw error;
    }
  },

  // Google認証情報を削除
  async deleteGoogleToken(workspaceId: string, userId: string) {
    try {
      const response = await Service.delete(`/google/token/${workspaceId}/${userId}/`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('deleteGoogleToken#Response', response);

      return response;
    } catch (error: any) {
      Log.error('deleteGoogleToken#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = '存在しないユーザーです。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました';
      }

      throw error;
    }
  },

  // Google OAuth URLを作成し返す
  async getAuthUrl() {
    try {
      const response = await Service.get('/google/authurl/', {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getAuthUrl#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getAuthUrl#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました';
      }

      throw error;
    }
  },
};
