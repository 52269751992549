



















import Vue from 'vue';

export default Vue.extend({
  name: 'BaseLabeledRow',

  inheritAttrs: false,

  props: {
    // ボディのVColオプション
    bodyColProps: {
      type: Object,
      default: undefined,
    },

    // ラベルテキスト
    label: {
      type: String,
      default: undefined,
    },

    // ラベルのVColオプション
    labelColProps: {
      type: Object,
      default() {
        return {
          cols: '3',
        };
      },
    },

    // プレーンテキスト表示かどうか
    plaintext: {
      type: Boolean,
    },
  },
});
