import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  // Dashboard SCAgent application
  async getApplicationList(params: { workspaceId: string }) {
    try {
      const response = await Service.get(`/application/list/${params.workspaceId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });
      Log.debug('getApplicationList', response);
      return response.data;
    } catch (error: any) {
      Log.error('getVoiceXData#Error', error);

      throw error;
    }
  },
  // Dashboard get department List
  async getDepartmentList(params: { workspaceId: string }) {
    try {
      const response = await Service.get(`/department/list/${params.workspaceId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });
      Log.debug('getDepartmentList', response);
      return response.data;
    } catch (error: any) {
      Log.error('getVoiceXData#Error', error);

      throw error;
    }
  },
  // Dashboard get department Member List
  async getDepartmentMemberList(params: { workspaceId: string; departmentId: string }) {
    try {
      const response = await Service.get(
        `/departmentmember/list/${params.workspaceId}/${params.departmentId}`,
        {
          headers: generateHeaders('Bearer', { Accept: 'application/json' }),
        }
      );
      Log.debug('getDepartmentMemberList', response);
      return response.data;
    } catch (error: any) {
      Log.error('getVoiceXData#Error', error);

      throw error;
    }
  },
  // Dashboard get extention A List
  async getExtentionAList(params: { workspaceId: string; state: string }) {
    try {
      // const response = await Service.post(`/voicex/extension/list/`,JSON.stringify(params))
      const response = await Service.post('/voicex/extension/list/', JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });
      Log.debug('getExtentionAList', response);
      return response.data;
    } catch (error: any) {
      Log.error('getExtentionAList#Error', error);

      throw error;
    }
  },
  // Dashboard get extention List
  async getExtentionList(params: { workspaceId: string }) {
    try {
      const response = await Service.get(`/voicexuser/list/${params.workspaceId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });
      Log.debug('getExtentionList', response);
      return response.data;
    } catch (error: any) {
      Log.error('getExtentionList#Error', error);
      throw error;
    }
  },
  // Dashboard get department List
  async getProjectList(params: { workspaceId: string }) {
    try {
      const response = await Service.get(`/project/list/${params.workspaceId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });
      Log.debug('getProjectList', response);
      return response.data;
    } catch (error: any) {
      Log.error('getVoiceXData#Error', error);

      throw error;
    }
  },
  // Dashboard get project Member List
  async getProjectMemberList(params: { workspaceId: string; projectId: string }) {
    try {
      const response = await Service.get(
        `/projectmember/list/${params.workspaceId}/${params.projectId}`,
        {
          headers: generateHeaders('Bearer', { Accept: 'application/json' }),
        }
      );
      Log.debug('getProjectMemberList', response);
      return response.data;
    } catch (error: any) {
      Log.error('getVoiceXData#Error', error);

      throw error;
    }
  },
  // Dashboard SCAgent breakdown
  async getSCAgentBreakDown(params: {
    workspaceId: string;
    term: {
      kind: string;
      date: string;
    };
    target: {
      departmentId?: string;
      projectId?: string;
      userId?: string;
    };
  }) {
    try {
      const response = await Service.post(
        `/dashboard3/activitylogger/breakdown`,
        JSON.stringify(params),
        {
          headers: generateHeaders('Bearer', { Accept: 'application/json' }),
        }
      );
      Log.debug('getSCAgentBreakDown', response);
      return response.data;
    } catch (error: any) {
      Log.error('getVoiceXData#Error', error);

      throw error;
    }
  },
  // Dashboard SCAgent time
  async getSCAgentData(params: {
    workspaceId: string;
    term: {
      kind: string;
      date: string;
    };
    target: {
      departmentId?: string;
      projectId?: string;
      userId?: string;
    };
  }) {
    try {
      const response = await Service.post(`/dashboard3/activitylogger/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });
      Log.debug('getSCAgentData', response);
      return response.data;
    } catch (error: any) {
      Log.error('getVoiceXData#Error', error);

      throw error;
    }
  },
  async getSCAgentUsageStatus(params: {
    workspaceId: string;
    term: {
      kind: string;
      date: string;
    };
    target?: {
      departmentId?: string;
      projectId?: string;
      userId?: string;
    };
    gFlg?: number;
  }) {
    try {
      const response = await Service.post(
        `/dashboard3/activitylogger/usagestats/`,
        JSON.stringify(params),
        {
          headers: generateHeaders('Bearer', { Accept: 'application/json' }),
        }
      );
      Log.debug('getSCAgentUsageStatus', response);
      return response.data;
    } catch (error: any) {
      Log.error('getSCAgentUsageStatus#Error', error);

      throw error;
    }
  },
  async getUsageStatus(params: {
    workspaceId: string;
    term: {
      kind: string;
      date: string;
    };
    target?: {
      departmentId?: string;
      projectId?: string;
      phone?: {
        kind: string;
        no: string;
      };
    };
  }) {
    try {
      const response = await Service.post('/dashboard3/voicex/usagestats', JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });
      Log.debug('getVoiceXData#Response', response);
      return response.data;
    } catch (error: any) {
      Log.error('getVoiceXEarlier#Error', error);

      throw error;
    }
  },
  // Dashboard get user list
  async getUserList(params: { workspaceId: string }) {
    try {
      const response = await Service.get(`/user/list/${params.workspaceId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });
      Log.debug('getUserList', response);
      return response.data;
    } catch (error: any) {
      Log.error('getUserList#Error', error);

      throw error;
    }
  },
  async getVoiceXBreakdown(params: {
    workspaceId: string;
    term: {
      kind: string;
      date: string;
    };
    target?: {
      departmentId?: string;
      projectId?: string;
      userId?: string;
    };
  }) {
    try {
      const response = await Service.post(`/dashboard3/voicex/breakdown`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });
      Log.debug('getVoiceXBreakdown#Response', response);
      return response.data;
    } catch (error: any) {
      Log.error('getVoiceXBreakdown#Error', error);

      throw error;
    }
  },
  // Dashboard VoiceXのデータ取得
  async getVoiceXData(params: {
    callType: number;
    workspaceId: string;
    term: {
      kind: string;
      date: string;
    };
    target?: {
      departmentId?: string;
      projectId?: string;
      phone?: { kind: string; no: string };
    };
  }) {
    try {
      const response = await Service.post(`/dashboard3/voicex/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });
      Log.debug('getVoiceXData#Response', response);
      return response.data;
    } catch (error: any) {
      Log.error('getVoiceXData#Error', error);

      throw error;
    }
  },
  // 前期比取得
  async getVoiceXEarlier(params: {
    workspaceId: string;
    term: {
      kind: string;
      date: string;
    };
    target?: {
      departmentId?: string;
      projectId?: string;
      phone?: { kind: string; no: string };
    };
  }) {
    try {
      const response = await Service.post(`/dashboard3/voicex/earlier`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });
      Log.debug('getVoiceXEarlier#Response', response);
      return response.data;
    } catch (error: any) {
      Log.error('getVoiceXEarlier#Error', error);

      throw error;
    }
  },
  // ランキング取得
  async getVoiceXRanking(params: {
    workspaceId: string;
    term: {
      kind: string;
      date: string;
    };
    target?: {
      departmentId?: string;
      projectId?: string;
      userId?: string;
    };
  }) {
    try {
      const response = await Service.post(`/dashboard3/voicex/ranking`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });
      Log.debug('getVoiceXData#Response', response);
      return response.data;
    } catch (error: any) {
      Log.error('getVoiceXData#Error', error);

      throw error;
    }
  },
};
