import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';

class UIInfoState {
  // 既読のお知らせのIDリスト (事業者別)
  readInfoIds: {
    [partnerId: string]: number[];
  } = {};
}

class UIInfoGetters extends Getters<UIInfoState> {
  // 特定事業者の既読のお知らせのIDリスト
  readInfoIds(partnerId: string) {
    return this.state.readInfoIds[partnerId] || [];
  }
}

class UIInfoMutations extends Mutations<UIInfoState> {
  addPartner(payload: { partnerId: string }) {
    this.state.readInfoIds[payload.partnerId] = [];
  }

  addReadInfoId(payload: { partnerId: string; infoId: number }) {
    this.state.readInfoIds[payload.partnerId].push(payload.infoId);
  }
}

class UIInfoActions extends Actions<UIInfoState, UIInfoGetters, UIInfoMutations, UIInfoActions> {
  // 既読のお知らせのIDをリストに追加する
  addReadInfoId(payload: { partnerId: string; infoId: number }) {
    const partnersReadInfoIds = this.state.readInfoIds[payload.partnerId];

    if (partnersReadInfoIds === undefined) {
      // リスト内に事業者が存在しない場合は、先に事業者を追加してからIDを追加する
      this.mutations.addPartner({ partnerId: payload.partnerId });
      this.mutations.addReadInfoId(payload);
    } else if (!partnersReadInfoIds.includes(payload.infoId)) {
      this.mutations.addReadInfoId(payload);
    }
  }
}

export const UIInfo = new Module({
  actions: UIInfoActions,
  getters: UIInfoGetters,
  mutations: UIInfoMutations,
  state: UIInfoState,
});

export const UIInfoMapper = createMapper(UIInfo);
