import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';

// プロジェクト
export type Project = {
  projectId: string;
  projectName: string;
  _id: string;
  regDate: string;
  workspaceId: string;
};

class UIProjectState {
  // プロジェクト一覧
  projectList: Project[] = [];
}

class UIProjectGetters extends Getters<UIProjectState> {}

class UIProjectMutations extends Mutations<UIProjectState> {
  setProjectList(payload: { projects: Project[] }) {
    this.state.projectList = payload.projects;
  }
}

class UIProjectActions extends Actions<
  UIProjectState,
  UIProjectGetters,
  UIProjectMutations,
  UIProjectActions
> {
  // プロジェクト情報をセットする
  setProjectList(payload: { projects: Project[] }) {
    this.mutations.setProjectList(payload);
  }
}

export const UIProject = new Module({
  actions: UIProjectActions,
  getters: UIProjectGetters,
  mutations: UIProjectMutations,
  state: UIProjectState,
});

export const UIProjectMapper = createMapper(UIProject);
