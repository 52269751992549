import _ from 'lodash';
import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  // チャットメッセージを取得する
  async getChat(workspaceId: string, userId: string) {
    try {
      const response = await Service.get(`/chat/${workspaceId}/${userId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getChat#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getChat#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = '存在しないユーザーです。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // チャットメッセージを既読にする
  async readChat(workspaceId: string, userId: string, params: { chatId: string }) {
    try {
      const response = await Service.put(`/chat/${workspaceId}/${userId}`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('readChat#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('readChat#Error', error);

      switch (error.status) {
        case 400:
          error.message = '正しいチャット情報が送られていません。';
          break;
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = '存在しないユーザーです。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 固定チャットメッセージを送信する
  async sendChat(params: {
    workspaceId: string;
    userId: string;
    memberId: string;
    messageId: string;
    replyId?: string;
  }) {
    try {
      const response = await Service.post(`/chat/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('sendChat#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('sendChat#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = '存在しないユーザーです。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 自由チャットメッセージを送信する
  async sendFreeChat(params: {
    workspaceId: string;
    userId: string;
    memberId: string;
    message: string;
    replyId?: string;
  }) {
    try {
      const p = _.assign(params, { messageId: '0' });
      const response = await Service.post(`/chat/`, JSON.stringify(p), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('sendFreeChat#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('sendFreeChat#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = '存在しないユーザーです。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },
};
