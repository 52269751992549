































import Vue from 'vue';

export default Vue.extend({
  name: 'BaseDialog',

  inheritAttrs: false,

  props: {
    // 閉じるボタンをひょうじするか
    closeButton: {
      type: Boolean,
      // プロジェクトデフォルト値を設定するために無効化
      // eslint-disable-next-line vue/no-boolean-default
      default: true,
    },

    // コンポーネントのクラス名
    contentClass: {
      type: String,
      default: '',
    },

    // ダイアログの最大幅
    maxWidth: {
      type: [Number, String],
      default: 800,
    },

    // 外側クリック、escキーを無効にするか
    persistent: {
      type: Boolean,
    },

    // ダイアログタイトル
    title: {
      type: String,
      default: undefined,
    },

    // 表示中かどうか
    value: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    className(): string {
      return ['sc-dialog', this.contentClass].join(' ');
    },

    computedValue: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('update:value', value);
      },
    },
  },
});
