import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  // ワークスペース情報を登録する
  async createWorkspace(params: { workspaceName: string; email: string; tel: string }) {
    try {
      const response = await Service.post(`/workspace/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('createWorkspace#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('createWorkspace#Error', error);

      switch (error.status) {
        case 400:
          error.message = '不正なリクエストです。';
          break;
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 409:
          error.message = 'メールアドレスまたはサブドメインがすでに存在します。。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // ワークスペース情報を返す
  async getWorkspace(workspaceId: string) {
    try {
      const response = await Service.get(`/workspace/${workspaceId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getWorkspace#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getWorkspace#Error', error);

      switch (error.status) {
        case 400:
          error.message = '不正なリクエストです。';
          break;
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = 'ワークスペースが見つかりません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // サブドメインからワークスペース情報を返す
  async getWorkspaceBySubDomain(params: { subDomain: string }) {
    try {
      const response = await Service.post(`/workspace/id/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getWorkspaceBySubDomain#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getWorkspaceBySubDomain#Error', error);

      switch (error.status) {
        case 400:
          error.message = '不正なリクエストです。';
          break;
        case 404:
          error.message = 'サブドメインが見つかりません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // ワークスペース情報を更新する
  async updateWorkspace(workspaceId: string, params: { image?: string; workspaceName: string }) {
    try {
      const response = await Service.put(`/workspace/${workspaceId}`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('updateWorkspace#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('updateWorkspace#Error', error);

      switch (error.status) {
        case 400:
          error.message = '不正なリクエストです。';
          break;
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = 'ワークスペースが見つかりません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },
};
