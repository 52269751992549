import _ from 'lodash';
import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';

// クイックチャットの定形文
export type Phrase = {
  _id: string;
  workspaceId: string;
  phraseId: string;
  kind: 'chat' | 'reply';
  text: string;
  regDate: string;
};

// ひとつのクイックチャット
export type Chat = {
  _id: string;
  workspaceId: string;
  chatId: string;
  fromId: string;
  fromName?: string;
  toId: string;
  messageId: string;
  message: string;
  unread: number;
  replied: number;
  regDate: string;
  selected: boolean;
};

// 受信したクイックチャット
export type ReceivedChat = {
  kind: 'chat';
  chatId: string;
  workspaceId: string;
  fromId: string;
  toId: string;
  messageId: string;
  message: string;
  regDate: string;
};

class UIChatState {
  // クイックチャット定形文一覧
  phraseList: Phrase[] = [];

  // クイックチャット受信一覧
  quickChatMessages: Chat[] = [];

  // クイックチャット返信先チャットID
  quickChatReplyChatId = '';

  // クイックチャット返信先ユーザー
  quickChatReplyUserId = '';
}

class UIChatGetters extends Getters<UIChatState> {
  // クイックチャット送信用の文書一覧
  phraseListForChat() {
    return _.filter(this.state.phraseList, { kind: 'chat' });
  }

  // クイックチャット返信用の文書一覧
  phraseListForReply() {
    return _.filter(this.state.phraseList, { kind: 'reply' });
  }
}

class UIChatMutations extends Mutations<UIChatState> {
  clearQuickchatReplyInfo() {
    this.state.quickChatReplyChatId = '';
    this.state.quickChatReplyUserId = '';
  }

  setPhraseList(payload: { list: Phrase[] }) {
    this.state.phraseList = payload.list;
  }

  setQuickChatMessages(payload: { messages: Chat[] }) {
    this.state.quickChatMessages = payload.messages;
  }

  setQuickChatRead(payload: { chatId: string }) {
    const chat = _.find(this.state.quickChatMessages, { chatId: payload.chatId });
    if (chat) {
      chat.unread = 0;
    }
  }

  setQuickchatReplyInfo(payload: { userId: string; chatId: string }) {
    this.state.quickChatReplyChatId = payload.chatId;
    this.state.quickChatReplyUserId = payload.userId;
  }
}

class UIChatActions extends Actions<UIChatState, UIChatGetters, UIChatMutations, UIChatActions> {
  // クイックチャット返信情報をクリアする
  clearQuickchatReplyInfo() {
    this.mutations.clearQuickchatReplyInfo();
  }

  // クイックチャット定形文一覧を設定する
  setPhraseList(payload: { list: Phrase[] }) {
    this.mutations.setPhraseList(payload);
  }

  // クイックチャット受信一覧情報をセット
  setQuickChatMessages(payload: { messages: Chat[] }) {
    this.mutations.setQuickChatMessages(payload);
  }

  // クイックチャット一覧内のメッセージを既読状態にする
  setQuickChatRead(payload: { chatId: string }) {
    this.mutations.setQuickChatRead(payload);
  }

  // クイックチャット返信情報をセット
  setQuickchatReplyInfo(payload: { userId: string; chatId: string }) {
    this.mutations.setQuickchatReplyInfo(payload);
  }
}

export const UIChat = new Module({
  actions: UIChatActions,
  getters: UIChatGetters,
  mutations: UIChatMutations,
  state: UIChatState,
});

export const UIChatMapper = createMapper(UIChat);
