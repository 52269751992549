import _ from 'lodash';
import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';
import store from '@/store';
import { DomainAuth } from '@/store/modules/domain/auth';

export default {
  async checkSession(params: { workspaceId: string; onetimeSession: string }) {
    try {
      const response = await Service.post(`/session/check/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('checkSession#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('checkSession#Error', error);

      switch (error.status) {
        case 400:
          error.message = '不正なリクエストです。';
          break;
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = '存在しないユーザーです。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  async login(params: { workspaceId: string; loginId: string; password: string }) {
    try {
      const response = await Service.post(`/auth/token`, JSON.stringify(params), {
        headers: { 'Content-Type': 'application/json' },
      });

      Log.debug('login#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('login#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証できませんでした。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  async logout() {
    try {
      const response = await Service.post(`/auth/reset`, JSON.stringify({}), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('logout#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('logout#Error', error);

      throw error;
    }
  },

  async requestPasswordReset(params: { workspaceId: string; email: string }) {
    try {
      const response = await Service.post(`/password/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('requestPasswordReset#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('requestPasswordReset#Error', error);

      throw error;
    }
  },

  async tokenRefresh(params: { workspaceId: string; userId: string }) {
    try {
      const context = DomainAuth.context(store);
      const { token, tokenType } = context.state.token;
      const postParams = _.assign({}, params, { token });

      const response = await Service.post(`/auth/token_refresh`, JSON.stringify(postParams), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('tokenRefresh#Response', response);

      // トークンをストア
      context.actions.setToken({
        token: response.data.token,
        tokenType,
      });

      return response.data;
    } catch (error: any) {
      Log.error('tokenRefresh#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証できませんでした。';
          break;
        case 403:
          error.message = '更新が可能な有効ユーザーではありません。';
          break;
        case 404:
          error.message = '対象の有効なユーザーが存在しません。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  async updateUserAttributes(
    workspaceId: string,
    userId: string,
    params: {
      email?: string;
      userName?: string;
      kana?: string;
      image?: string;
      position?: string;
      phoneNo?: string;
      voiceXs?: {
        phoneType: string;
        phoneNo: string;
      };
      adminLevel?: number | undefined;
      status?: number | undefined;
      emotion?: number;
      place?: string;
      currentApp?: {
        appId: string;
        startedAt: string;
        memberId?: string;
      };
    }
  ) {
    try {
      const response = await Service.put(`/user/${workspaceId}/${userId}`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('updateUserAttributes#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('updateUserAttributes#Error', error);

      throw error;
    }
  },

  // paramsはサインアップかパスワード変更によって内容が変わるため{}で設定
  async updateUserOnetime(onetimeSession: string, params: Record<string, unknown>) {
    try {
      const response = await Service.put(
        `/user/onetime/${onetimeSession}`,
        JSON.stringify(params),
        {
          headers: generateHeaders('Bearer', { Accept: 'application/json' }),
        }
      );

      Log.debug('updateUserOnetime#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('updateUserOnetime#Error', error);

      throw error;
    }
  },

  async uploadImageOnetime(
    onetimeSession: string,
    kind: 'workspace' | 'user' | 'appli',
    file: File,
    options?: {
      width?: number;
      height?: number;
    }
  ) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('kind', kind);
    if (options) {
      formData.append('options', JSON.stringify(options));
    }
    try {
      const response = await Service.post(`/image/upload/onetime/${onetimeSession}`, formData, {
        headers: generateHeaders('Bearer', {
          Accept: 'application/json',
          'content-type': 'multipart/form-data',
        }),
      });

      Log.debug('uploadImage#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('uploadImage#Error', error);

      throw error;
    }
  },
};
