import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  // 役職情報を登録する
  async createPosition(params: { workspaceId: string; name: string }) {
    try {
      const response = await Service.post(`/position/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('createPosition#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('createPosition#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 役職情報を削除
  async deletePosition(workspaceId: string, positionId: string) {
    try {
      const response = await Service.delete(`/position/${workspaceId}/${positionId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('deletePosition#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('deletePosition#Error', error);

      switch (error.status) {
        case 400:
          error.message = '不正なリクエストです。';
          break;
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = '役職が見つかりません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 役職情報を返す
  async getPosition(workspaceId: string, positionId: string) {
    try {
      const response = await Service.get(`/position/${workspaceId}/${positionId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getPosition#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getPosition#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = '役職が見つかりません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 役職一覧を返す
  async getPositionList(workspaceId: string) {
    try {
      const response = await Service.get(`/position/list/${workspaceId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getPositionList#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getPositionList#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 役職一覧を返す(ワンタイムセッション認証)
  async getPositionListOnetime(onetimeSession: string) {
    try {
      const response = await Service.get(`/position/onetime/list/${onetimeSession}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getPositionListOnetime#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getPositionListOnetime#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 役職情報を更新
  async updatePosition(workspaceId: string, positionId: string, params: { name: string }) {
    try {
      const response = await Service.put(
        `/position/${workspaceId}/${positionId}`,
        JSON.stringify(params),
        {
          headers: generateHeaders('Bearer', { Accept: 'application/json' }),
        }
      );

      Log.debug('updatePosition#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('updatePosition#Error', error);

      switch (error.status) {
        case 400:
          error.message = '不正なリクエストです。';
          break;
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = '役職が見つかりません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },
  // 役職情報を更新
  async updatePositionOrder(params: { workspaceId: string; orders: string[] }) {
    try {
      const response = await Service.post(`/position/order/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('updatePositionOrder#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('updatePositionOrder#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },
};
