import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  // ユーザー情報を登録する
  async createUser(params: {
    workspaceId: string;
    email: string;
    userName: string;
    kana: string;
    adminLevel?: number;
    departmentId?: number | string;
    projectIds?: string[];
    extNo?: number | string;
  }) {
    try {
      const response = await Service.post(`/user/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('createUser#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('createUser#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 409:
          error.message = '同じメールアドレスのユーザーがいるため作成できません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // ユーザーを(論理)削除する
  async deleteUser(workspaceId: string, userId: string) {
    try {
      const response = await Service.delete(`/user/${workspaceId}/${userId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('deleteUser#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('deleteUser#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = 'ユーザーが見つかりません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 指定されたユーザーの情報を返す
  async getUser(workspaceId: string, userId: string) {
    try {
      const response = await Service.get(`/user/${workspaceId}/${userId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getUser#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getUser#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = 'ユーザーが見つかりません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 指定されたワークスペースのユーザー一覧を返す
  async getUserList(workspaceId: string) {
    try {
      const response = await Service.get(`/user/all/${workspaceId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getUserList#Response', response);

      return response.data;
    } catch (error) {
      Log.error('getUserList#Error', error);

      throw error;
    }
  },

  // ワンタイムセッション再発行
  async onetimeReissue(params: { workspaceId: string; userId: string }) {
    try {
      const response = await Service.post(`/user/onetime/reissue/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('onetimeReissue#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('onetimeReissue#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // ユーザーを(物理)削除する
  async purgeUser(workspaceId: string, userId: string) {
    try {
      const response = await Service.delete(`/user/purge/${workspaceId}/${userId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('purgeUser#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('purgeUser#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = 'ユーザーが見つかりません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // ユーザー情報を更新する
  async updateUser(
    workspaceId: string,
    userId: string,
    params: {
      [key: string]: string | number;
    }
  ) {
    try {
      const response = await Service.put(`/user/${workspaceId}/${userId}`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('updateUser#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('updateUser#Error', error);

      switch (error.status) {
        case 400:
          error.message = '不正なリクエストです。';
          break;
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = 'ユーザーが見つかりません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },
};
