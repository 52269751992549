import dayjs from 'dayjs';
import _ from 'lodash';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Log from '@/resources/plugins/Logger/Log';
import ServiceFactory from '@/services/ui/ServiceFactory';
import WorkSpaceService from '@/services/ui/WorkspaceService';
import settings from '@/settings';
import store from '@/store';
import { DomainAuth } from '@/store/modules/domain/auth';
import { DomainStrapiInfo } from '@/store/modules/domainStrapi/info';
import { UICommon } from '@/store/modules/ui/common';
import { UIWorkspace } from '@/store/modules/ui/workspace';

const AuthService = ServiceFactory.get('auth');

// Containers
const TheContainerDefault = () => import('@/containers/TheContainerDefault.vue');
const TheContainerSimple = () => import('@/containers/TheContainerSimple.vue');

// Views
const SamplePage = () => import('@/views/dev/SamplePage.vue');
const HomePage = () => import('@/views/HomePage.vue');
const Group = () => import('@/views/Group.vue');
const Dashboard = () => import('@/views/Dashboard.vue');
const LoginPage = () => import('@/views/LoginPage.vue');

// pages
const PasswordResetReception = () => import('@/views/pages/PasswordResetReception.vue');
const PasswordReset = () => import('@/views/pages/PasswordReset.vue');
const Signup = () => import('@/views/pages/Signup.vue');
const SignupInput = () => import('@/views/pages/SignupInput.vue');
const DownloadApp = () => import('@/views/pages/DownloadApp.vue');
const SettingApp = () => import('@/views/pages/SettingApp.vue');
const LinkGoogleCalendar = () => import('@/views/pages/LinkGoogleCalendar.vue');
const GoogleCalendarLinkResult = () => import('@/views/pages/GoogleCalendarLinkResult.vue');

// admin
const Management = () => import('@/views/Management.vue');

Vue.use(VueRouter);

// 重要な情報が先に来るようにする (ただし、すべての要素で順序を統一する)
/* eslint-disable vue/sort-keys */
const routes: Array<RouteConfig> = [
  // ログイン前の画面
  {
    path: '/login',
    component: TheContainerSimple,
    children: [
      {
        path: '',
        name: 'Login',
        component: LoginPage,
        meta: { title: 'ログイン' },
      },
    ],
  },
  {
    path: '/password_reset',
    component: TheContainerSimple,
    children: [
      {
        path: 'reception',
        name: 'PasswordResetReception',
        component: PasswordResetReception,
        meta: { title: 'パスワード再設定受付' },
      },
      {
        path: '',
        name: 'PasswordReset',
        component: PasswordReset,
        meta: { title: 'パスワード再設定' },
      },
    ],
  },
  {
    path: '/signup',
    component: TheContainerSimple,
    children: [
      {
        path: '',
        name: 'Signup',
        component: Signup,
        meta: { title: 'サインアップ受付' },
      },
      {
        path: 'input',
        name: 'SignupInput',
        component: SignupInput,
        meta: { title: 'サインアップ' },
      },
      {
        path: 'download',
        name: 'DownloadApp',
        component: DownloadApp,
        meta: { title: 'Shine Connect Agentダウンロード' },
      },
      {
        path: 'setting',
        name: 'SettingApp',
        component: SettingApp,
        meta: { title: '初期設定' },
      },
      {
        path: 'calendar',
        name: 'LinkGoogleCalendar',
        component: LinkGoogleCalendar,
        meta: { title: 'Googleカレンダー連携' },
      },
    ],
  },
  // ログイン後の画面
  {
    path: '/',
    component: TheContainerDefault,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'Home',
        component: HomePage,
        meta: {
          isMenuItem: true,
          icon: 'home',
          title: 'ホーム',
          position: 'middle',
          class: 'home-page',
          admin: false,
        },
        props: (route) => ({
          icon: route.meta.icon,
          group: route.meta.position,
          admin: route.meta.admin,
        }),
      },
      {
        path: 'group',
        name: 'Group',
        component: Group,
        meta: {
          isMenuItem: true,
          icon: 'channel',
          title: 'グループ',
          position: 'middle',
          class: 'group-page',
          admin: false,
        },
        props: (route) => ({
          icon: route.meta.icon,
          group: route.meta.position,
          admin: route.meta.admin,
        }),
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          isMenuItem: true,
          icon: 'dashboard',
          title: 'ダッシュボード',
          position: 'bottom',
          admin: false,
        },
        props: (route) => ({
          icon: route.meta.icon,
          group: route.meta.position,
          admin: route.meta.admin,
        }),
      },
      {
        path: 'management',
        name: 'Management',
        component: Management,
        meta: { isMenuItem: true, icon: 'cog', title: '管理', position: 'middle', admin: true },
        props: (route) => ({
          icon: route.meta.icon,
          group: route.meta.position,
          admin: route.meta.admin,
        }),
      },
    ],
  },
  {
    path: '/exlink-result',
    component: TheContainerDefault,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'google-calendar/:result',
        name: 'GoogleCalendarLinkResult',
        component: GoogleCalendarLinkResult,
      },
    ],
  },
];
/* eslint-enable vue/sort-keys */

if (process.env.NODE_ENV === 'development') {
  // 重要な情報が先に来るようにする (ただし、すべての要素で順序を統一する)
  /* eslint-disable vue/sort-keys */
  routes.push({
    path: '/dev',
    component: TheContainerDefault,
    children: [
      {
        path: 'sample',
        name: 'Sample',
        component: SamplePage,
        meta: { isMenuItem: true, icon: 'home', title: '開発用サンプル', position: 'bottom' },
        props: (route) => ({
          icon: route.meta.icon,
          title: route.meta.title,
          group: route.meta.position,
        }),
      },
    ],
  });
  /* eslint-enable vue/sort-keys */
}

routes.push({
  path: '/logout',
  redirect: '/login',
});

routes.push({
  path: '*',
  redirect: '/',
});

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 }),
});

// 遷移処理中にローディング表示するためにフラグを立てる
router.beforeEach((to, from, next) => {
  const context = UICommon.context(store);

  context.actions.setNavigating({ navigating: true });
  next();
});

// ログイン画面遷移時に不要になった情報をクリアする
// router.beforeEach((to, from, next) => {
//   if (to.name === 'Login') {
//     const domainAuthContext = DomainAuth.context(store);
//     const domainStrapiInfoContext = DomainStrapiInfo.context(store);
//     domainAuthContext.actions.clearToken();
//     domainAuthContext.actions.clearUserAttributes();
//     domainStrapiInfoContext.actions.clearInfos();
//   }

//   next();
// });

// 認証中にドメイン変更されたらログイン画面へリダイレクト
router.beforeEach((to, from, next) => {
  const domainAuthContext = DomainAuth.context(store);
  const isAuthenticated = domainAuthContext.getters.isAuthenticated(dayjs());

  if (to.name !== 'Login' && isAuthenticated) {
    const urlObj = new URL(window.location.href);
    const currentDomain = urlObj.hostname;

    if (domainAuthContext.state.domain !== currentDomain) {
      next('/login');
    } else {
      next();
    }
  } else {
    next();
  }
});

// サブドメインの場合ログイン画面表示変更またはメインドメインへリダイレクト
router.beforeEach(async (to, from, next) => {
  try {
    const hostStrings = window.location.host.split('.');
    const domain = hostStrings[0];
    const context = UIWorkspace.context(store);

    if (domain !== 'console' && domain !== 'local') {
      // サブドメインの場合はワークスペース情報を取得
      const workspace = await WorkSpaceService.getWorkspaceBySubDomain({
        subDomain: domain,
      });
      // ワークスペース情報をセット
      context.actions.setWorkspaceSpecified(workspace);
      next();
    } else {
      // メインドメインの場合
      next();
    }
  } catch (error: any) {
    Log.error(error);
    switch (error.status) {
      case 404:
        // リダイレクト
        window.location.assign(settings.mainDomainURL);
        break;
      default:
    }
    next();
  }
  next();
});

// 認証状態によるナビゲーションガード
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const domainAuthContext = DomainAuth.context(store);
    const isAuthenticated = domainAuthContext.getters.isAuthenticated(dayjs());

    if (isAuthenticated) {
      next();
    } else if (from.name === 'Login') {
      // ログイン画面からの戻るボタンで画面遷移処理中のままにならないよう防止
      const context = UICommon.context(store);
      context.actions.setNavigating({ navigating: false });
      next(false);
    } else if (to.redirectedFrom !== undefined) {
      if (to.redirectedFrom.indexOf('signup') !== -1) {
        // サインアップの場合
        const oneTimeSession = window.location.href.substring(
          window.location.href.lastIndexOf('/') + 1
        );
        // セッションIDを保存
        sessionStorage.setItem('oneTimeSession', oneTimeSession);
        router.push({ path: 'signup' });
      } else if (to.redirectedFrom.indexOf('forgot') !== -1) {
        // パスワード再設定の場合
        const oneTimeSession = window.location.href.substring(
          window.location.href.lastIndexOf('/') + 1
        );
        // セッションIDを保存
        sessionStorage.setItem('oneTimeSession', oneTimeSession);
        router.push({ path: 'password_reset' });
      }
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

// 画面遷移時にポップアップ類を消す
// router.beforeEach((to, from, next) => {
//   const commonContext = UICommon.context(store);
//   const { hideAllPopup, hidePopupBackgroundLayer } = commonContext.actions;

//   hideAllPopup();
//   hidePopupBackgroundLayer();

//   next();
// });

// 認証が必要な画面へのナビゲーションが確定後にトークンリフレッシュを行う
router.afterEach(async (to) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    try {
      const domainAuthContext = DomainAuth.context(store);
      const { userAttributes } = domainAuthContext.state;

      await AuthService.tokenRefresh({
        userId: userAttributes.userId,
        workspaceId: userAttributes.workspaceId,
      });
    } catch (error: any) {
      Log.error(error);
      const context = UICommon.context(store);
      context.actions.setErrorMessage({ text: error.message });
    }
  }
});

// ナビゲーションが確定後にドキュメントタイトルを変更する
router.afterEach((to) => {
  const { title } = to.meta;

  if (_.isEmpty(settings.deployTargetInPageTitle)) {
    if (_.isEmpty(title)) {
      document.title = 'Shine Connect';
    } else {
      document.title = `${title} | Shine Connect`;
    }
  } else if (_.isEmpty(title)) {
    document.title = `${settings.deployTargetInPageTitle} | Shine Connect`;
  } else {
    document.title = `${settings.deployTargetInPageTitle} | ${title} | Shine Connect`;
  }
});

// 遷移処理中のローディング表示を終了するためにフラグを消す
router.afterEach(() => {
  const context = UICommon.context(store);

  context.actions.setNavigating({ navigating: false });
});

export default router;
