import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import jwt from 'jsonwebtoken';
import _ from 'lodash';
import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';

// トークン
type Token = {
  token: string;
  tokenType: string;
};

// ユーザー属性
export type UserAttributes = {
  agents?: {
    deviceId: string;
    installId: string;
  }[];
  workspaceId: string;
  userId: string;
  adminLevel: number | undefined;
  email: string;
  google: {
    resourceId: string;
  };
  userName: string;
  kana: string;
  image: string;
  departments: {
    departmentId: string;
    leader: number;
    departmentName: string;
  }[];
  position: string;
  projects: {
    projectId: string;
    projectName: string;
  }[];
  phoneNo: string;
  voiceXs: {
    phoneNo: string;
    phoneType: string;
  }[];
  timezone: string;
  status: number | undefined;
  emotion: number;
  place: string;
  device: string;
  currentApp: {
    appId: string;
    startedAt: string;
    memberId: string;
  };
  webPushToken: string[];
  webPushEnabled: boolean;
  chatUnread: number | undefined;
  regDate: string;
  updDate: string;
};

// ユーザー属性の一部
export type PartialUserAttributes = Partial<UserAttributes>;

// トークンの初期値
const initialToken: Readonly<Token> = Object.freeze({
  token: '',
  tokenType: '',
});

// ユーザー属性の初期値
const initialUserAttributes: Readonly<UserAttributes> = Object.freeze({
  // 型定義の順序で記述
  /* eslint-disable vue/sort-keys */
  workspaceId: '',
  userId: '',
  adminLevel: undefined,
  email: '',
  google: {
    resourceId: '',
  },
  userName: '',
  kana: '',
  image: '',
  departments: [],
  position: '',
  projects: [],
  phoneNo: '',
  voiceXs: [],
  timezone: '',
  status: undefined,
  emotion: 0,
  place: '',
  device: '',
  currentApp: {
    appId: '',
    startedAt: '',
    memberId: '',
  },
  webPushToken: [],
  webPushEnabled: false,
  chatUnread: undefined,
  regDate: '',
  updDate: '',
  /* eslint-enable vue/sort-keys */
});

class DomainAuthState {
  // ログイン時のドメイン
  domain = '';

  // 有効期限
  expireDate: dayjs.Dayjs | undefined = undefined;

  // トークン
  token: Token = _.cloneDeep(initialToken);

  // ユーザー属性
  userAttributes: UserAttributes = _.cloneDeep(initialUserAttributes);
}

class DomainAuthGetters extends Getters<DomainAuthState> {
  isAuthenticated(now: dayjs.Dayjs) {
    if (this.state.expireDate === undefined) {
      return false;
    }
    if (!dayjs.isDayjs(now)) {
      return false;
    }

    dayjs.extend(isSameOrAfter);
    return this.state.expireDate.isSameOrAfter(now);
  }

  isToken() {
    return this.state.token;
  }
}

class DomainAuthMutations extends Mutations<DomainAuthState> {
  clearDomain() {
    this.state.domain = '';
  }

  clearExpireDate() {
    this.state.expireDate = undefined;
  }

  clearToken() {
    this.state.token = _.cloneDeep(initialToken);
  }

  clearUserAttributes() {
    this.state.userAttributes = _.cloneDeep(initialUserAttributes);
  }

  setDomain(payload: { domain: string }) {
    this.state.domain = payload.domain;
  }

  setExpireDate(payload: { date: dayjs.Dayjs }) {
    this.state.expireDate = payload.date;
  }

  setToken(payload: Token) {
    this.state.token = payload;
  }

  setUserAttributes(payload: UserAttributes) {
    this.state.userAttributes = payload;
  }
}

class DomainAuthActions extends Actions<
  DomainAuthState,
  DomainAuthGetters,
  DomainAuthMutations,
  DomainAuthActions
> {
  clearDomain() {
    // ドメインをクリアする
    this.mutations.clearDomain();
  }

  // トークンをクリアする
  clearToken() {
    this.mutations.clearToken();
    this.mutations.clearExpireDate();
  }

  // ユーザー属性情報をクリアする
  clearUserAttributes() {
    this.mutations.clearUserAttributes();
  }

  // 認証情報をセッションストレージから復元する
  restoreAuth() {
    const storageItem = sessionStorage.getItem('scAuth');

    if (_.isString(storageItem)) {
      const storageItemJSON = JSON.parse(storageItem);

      const tokenInfo = {
        token: _.get(storageItemJSON, 'domain.auth.token.token'),
        tokenType: _.get(storageItemJSON, 'domain.auth.token.tokenType'),
      };

      this.mutations.setToken(tokenInfo);

      // 有効期限をセット
      const decoded = jwt.decode(tokenInfo.token, { complete: true });
      const expMsec = _.get(decoded, 'payload.exp', 0);
      const expire = dayjs(expMsec * 1000);
      this.mutations.setExpireDate({ date: expire });
    }
  }

  // ドメインをセット
  setDomain(payload: { domain: string }) {
    this.mutations.setDomain(payload);
  }

  // トークンを設定する(トークンリフレッシュ後もこちらで)
  setToken(payload: Token) {
    this.mutations.setToken(payload);

    // 有効期限をセット
    const decoded = jwt.decode(payload.token, { complete: true });
    const expMsec = _.get(decoded, 'payload.exp', 0);
    const expire = dayjs(expMsec * 1000);
    this.mutations.setExpireDate({ date: expire });
  }

  // ユーザー情報をセット
  setUserAttributes(payload: UserAttributes) {
    this.mutations.setUserAttributes(payload);
  }

  // 特定の属性情報のみをセット
  setUserAttributesSpecified(payload: PartialUserAttributes) {
    const newUserAttributes = _.assign({}, this.state.userAttributes, payload);

    this.mutations.setUserAttributes(newUserAttributes);
  }
}

export const DomainAuth = new Module({
  actions: DomainAuthActions,
  getters: DomainAuthGetters,
  mutations: DomainAuthMutations,
  state: DomainAuthState,
});

export const DomainAuthMapper = createMapper(DomainAuth);
