import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

interface GroupListItem {
  // APIの返却順で定義する
  /* eslint-disable @typescript-eslint/member-ordering */
  _id: string;
  workspaceId: string;
  userId: string;
  groupId: string;
  groupName: string;
  regDate: string;
  location: string;
  updDate: string;
  position: number;
  /* eslint-enable @typescript-eslint/member-ordering */
}

export default {
  // グループ情報を登録する
  async createGroup(params: { workspaceId: string; userId: string; groupName: string }) {
    try {
      const response = await Service.post(`/group/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('createGroup#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('createGroup#Error', error);

      throw error;
    }
  },

  // グループ情報を削除する
  async deleteGroup(workspaceId: string, userId: string, groupId: string) {
    try {
      const response = await Service.delete(`/group/${workspaceId}/${userId}/${groupId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('deleteGroup#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('deleteGroup#Error', error);

      throw error;
    }
  },

  // グループ情報を返す
  async getGroup(workspaceId: string, userId: string, groupId: string) {
    try {
      const response = await Service.get(`/group/${workspaceId}/${userId}/${groupId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getGroup#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getGroup#Error', error);

      throw error;
    }
  },

  // 指定されたワークスペース、ユーザーのグループ一覧を返す
  async getGroupList(workspaceId: string, userId: string): Promise<GroupListItem[]> {
    try {
      const response = await Service.get(`/group/list/${workspaceId}/${userId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getGroupList#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getGroupList#Error', error);

      throw error;
    }
  },

  // グループ情報を更新する
  async updateGroup(
    workspaceId: string,
    userId: string,
    groupId: string,
    args: { [field: string]: string | number }
  ) {
    try {
      const response = await Service.put(
        `/group/${workspaceId}/${userId}/${groupId}`,
        JSON.stringify(args),
        {
          headers: generateHeaders('Bearer', { Accept: 'application/json' }),
        }
      );

      Log.debug('updateGroup#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('updateGroup#Error', error);

      throw error;
    }
  },
};
