import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  // VoiceXユーザー情報を登録
  async createVoicexUser(params: { workspaceId: string; userId: string; telNo: string }) {
    try {
      const response = await Service.post(`/voicexuser/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('createVoicexUser#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('createVoicexUser#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // VoiceXユーザーを削除する
  async deleteVoicexUser(workspaceId: string, telNo: string) {
    try {
      const response = await Service.delete(`/voicexuser/${workspaceId}/${telNo}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('deleteVoicexUser#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('deleteVoicexUser#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = 'VoiceXユーザーが見つかりません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // VoiceX内線番号リストを取得
  async getExtensionList(params: { workspaceId: string; state: string }) {
    try {
      const response = await Service.post(`/voicex/extension/list/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getExtensionList#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getExtensionList#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 指定されたワークスペースのVoiceXユーザー一覧を返す
  async getVoicexUserList(workspaceId: string) {
    try {
      const response = await Service.get(`/voicexuser/list/${workspaceId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getVoicexUserList#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getVoicexUserList#Error', error);

      throw error;
    }
  },

  // VoiceXユーザー情報を更新
  async putVoicexUser(workspaceId: string, telNo: string, params: { userId: string }) {
    try {
      const response = await Service.put(
        `/voicexuser/${workspaceId}/${telNo}`,
        JSON.stringify(params),
        {
          headers: generateHeaders('Bearer', { Accept: 'application/json' }),
        }
      );

      Log.debug('putVoicexUser#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('putVoicexUser#Error', error);

      switch (error.status) {
        case 400:
          error.message = '不正なリクエストです。';
          break;
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = 'VoiceXユーザーが見つかりません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },
};
