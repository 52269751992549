import _ from 'lodash';
import Log from '../Logger/Log';
import validationMessages from './ValidationMessages';
import type { AlternativeMessages, ValidationStates } from './@types/VuelidateExtension.d';

// エラーメッセージ生成処理
// validationStates: 検証対象に設定されているすべてのバリデーションの状態
// alternativeMessages: デフォルトから置き換えるエラーメッセージ (キー: validationsで定義したキー名, 値: メッセージ)
const generateMessage = (
  validationStates: ValidationStates,
  alternativeMessages: AlternativeMessages = {}
) => {
  let message = '';

  const params = validationStates.$params;

  _.forEach(params, (value, key) => {
    if (validationStates[key]) {
      return;
    }

    const { type } = value;
    let tempMessage = '';

    if (_.isString(alternativeMessages[key])) {
      tempMessage += alternativeMessages[key];
    } else if (_.isString(type)) {
      if (_.isString(value.name)) {
        if (_.isString(value.particle)) {
          tempMessage += `${value.name}${value.particle}`;
        } else {
          tempMessage += `${value.name}は`;
        }
      }

      const matchedMessageDefinition = _.find(validationMessages, (v, k) => {
        return k === type;
      });

      if (_.isFunction(matchedMessageDefinition)) {
        tempMessage += matchedMessageDefinition(value);
      } else if (_.isString(matchedMessageDefinition)) {
        tempMessage += matchedMessageDefinition;
      } else {
        Log.debug('エラーメッセージが定義されていません。', type);

        tempMessage += `${key}エラーです。`;
      }
    } else {
      Log.debug('typeが文字列として定義されていません。', type);

      tempMessage += `${key}エラーです。`;
    }

    // 必須エラーの場合は他のエラーメッセージを出さない
    if (type === 'required') {
      message = tempMessage;
      // _.forEachの早期終了に必要
      // eslint-disable-next-line consistent-return
      return false;
    }

    message += tempMessage;
  });

  return message;
};

export default generateMessage;
