import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';

// グループ情報
export type Group = {
  workspaceId: string;
  userId: string;
  groupId: string;
  groupName: string;
  position: number;
};

class UIGroupState {
  // ユーザーのグループ一覧
  groupList: Group[] = [];
}

class UIGroupGetters extends Getters<UIGroupState> {}

class UIGroupMutations extends Mutations<UIGroupState> {
  setGroupList(payload: { groups: Group[] }) {
    this.state.groupList = payload.groups;
  }
}

class UIGroupActions extends Actions<
  UIGroupState,
  UIGroupGetters,
  UIGroupMutations,
  UIGroupActions
> {
  // ユーザー一覧を設定する
  setGroupList(payload: { groups: Group[] }) {
    this.mutations.setGroupList(payload);
  }
}

export const UIGroup = new Module({
  actions: UIGroupActions,
  getters: UIGroupGetters,
  mutations: UIGroupMutations,
  state: UIGroupState,
});

export const UIGroupMapper = createMapper(UIGroup);
