import _ from 'lodash';
import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';

export type Workspace = {
  atdBorderTime: string;
  atdLimitTime: string;
  email: string;
  image: string;
  subDomain: string;
  tel: string;
  voiceXDID: string;
  voiceXTenant: string;
  voiceXToken: string;
  workspaceId: string;
  workspaceName: string;
};

// ワークスペースの一部
export type PartialWorkspace = Partial<Workspace>;

const initialWorkspace: Readonly<Workspace> = Object.freeze({
  atdBorderTime: '05:00',
  atdLimitTime: '15',
  email: '',
  image: '',
  subDomain: '',
  tel: '',
  voiceXDID: '',
  voiceXTenant: '',
  voiceXToken: '',
  workspaceId: '',
  workspaceName: '',
});

class UIWorkspaceState {
  // ワークスペース情報
  workspace: Workspace = _.cloneDeep(initialWorkspace);
}

class UIWorkspaceGetters extends Getters<UIWorkspaceState> {}

class UIWorkspaceMutations extends Mutations<UIWorkspaceState> {
  clearWorkspace() {
    this.state.workspace = _.cloneDeep(initialWorkspace);
  }

  // ワークスペース情報をセット
  setWorkspace(payload: Workspace) {
    this.state.workspace = payload;
  }
}
class UIWorkspaceActions extends Actions<
  UIWorkspaceState,
  UIWorkspaceGetters,
  UIWorkspaceMutations,
  UIWorkspaceActions
> {
  clearWorkspace() {
    this.mutations.clearWorkspace();
  }

  setWorkspace(payload: Workspace) {
    this.mutations.setWorkspace(payload);
  }

  // 特定の情報のみをセット
  setWorkspaceSpecified(payload: PartialWorkspace) {
    const newWorkspace = _.assign({}, this.state.workspace, payload);
    this.mutations.setWorkspace(newWorkspace);
  }
}
export const UIWorkspace = new Module({
  actions: UIWorkspaceActions,
  getters: UIWorkspaceGetters,
  mutations: UIWorkspaceMutations,
  state: UIWorkspaceState,
});

export const UIWorkspaceMapper = createMapper(UIWorkspace);
