import dayjs from 'dayjs';
import Vue from 'vue';
import Log from '../Logger/Log';

// Dayjsを表示用の日時文字列に変換 (日時表示のデフォルトフォーマット使用)
// dayjsObject: 変換したいDayjsオブジェクト
const formatToDateTimeString = function formatToDateTimeString(
  this: Vue,
  dayjsObject: dayjs.Dayjs
) {
  if (this.$$dayjs.isDayjs(dayjsObject)) {
    return dayjsObject.format(this.$$dayjsFormats.displayFormatDateTimeDefault);
  }

  Log.debug('値がDayjsではありません。', dayjsObject);

  return '';
};

export default formatToDateTimeString;
