import generateMessage from './generateMessage';
import type { AlternativeMessages, ValidationStates } from './@types/VuelidateExtension.d';

// エラーメッセージ生成処理
// validationStates: 検証対象に設定されているすべてのバリデーションの状態
// alternativeMessages: デフォルトから置き換えるエラーメッセージ (キー: validationsで定義したキー名, 値: メッセージ)
const generateMessageIfError = (
  validationStates: ValidationStates,
  alternativeMessages: AlternativeMessages = {}
) => {
  if (validationStates.$error) {
    return generateMessage(validationStates, alternativeMessages);
  }

  return undefined;
};

export default generateMessageIfError;
