import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  // 部署情報を登録する
  async createDepartment(params: { workspaceId: string; departmentName: string }) {
    try {
      const response = await Service.post(`/department/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('createDepartment#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('createDepartment#Error', error);

      throw error;
    }
  },

  // 部署を削除する
  async deleteDepartment(workspaceId: string, departmentId: string) {
    try {
      const response = await Service.delete(`/department/${workspaceId}/${departmentId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('deleteDepartment#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('deleteDepartment#Error', error);

      switch (error.status) {
        case 400:
          error.message = '不正なリクエストです。';
          break;
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = '部署が見つかりません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 指定されたワークスペースの部署一覧を返す
  async getDepartmentList(workspaceId: string) {
    try {
      const response = await Service.get(`/department/list/${workspaceId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getDepartmentList#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getDepartmentList#Error', error);

      throw error;
    }
  },

  // 部署情報を更新する
  async updateDepartment(
    workspaceId: string,
    departmentId: string,
    params: { departmentName: string }
  ) {
    try {
      const response = await Service.put(
        `/department/${workspaceId}/${departmentId}`,
        JSON.stringify(params),
        {
          headers: generateHeaders('Bearer', { Accept: 'application/json' }),
        }
      );

      Log.debug('updateDepartment#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('updateDepartment#Error', error);

      switch (error.status) {
        case 400:
          error.message = '不正なリクエストです。';
          break;
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = '部署が見つかりません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },
};
