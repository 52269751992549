import _ from 'lodash';
import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';

export type FilterConditionPayload = {
  projects: {
    ids: string[];
  };
  departments: {
    ids: string[];
  };
  groups: {
    ids: string[];
  };
};

type ConditionItem = {
  ids: string[];
  operator: 0 | 1;
};

type MemberInfo = {
  adminLevel: number;
  chatUnread?: number;
  email: string;
  emotion?: number;
  image?: string;
  kana?: string;
  position?: string;
  regDate: string;
  status: number;
  updDate?: string;
  userId: string;
  userName?: string;
  workspaceId: string;
};

type Degree = {
  userId: string;
  degree: number;
};

export type FilterGroupName = 'projects' | 'departments' | 'groups';

export type FilterItem = {
  id: string;
  name: string;
  selected: boolean;
  applied: boolean;
};

export const InitialSearchConditions = {
  conditions: {
    projects: {
      ids: ['main'],
      operator: 0,
    },
  },
  operator: 0,
};

class UIMemberFilterState {
  // APIに渡すフィルター条件
  filter: {
    conditions: {
      projects: ConditionItem;
      departments: ConditionItem;
      groups: ConditionItem;
    };
    operator: 0 | 1;
  } = {
    // 定義の順番で記述
    /* eslint-disable vue/sort-keys */
    conditions: {
      projects: {
        ids: [],
        operator: 0,
      },
      departments: {
        ids: [],
        operator: 0,
      },
      groups: {
        ids: [],
        operator: 0,
      },
    },
    operator: 0,
    /* eslint-enable vue/sort-keys */
  };

  // フィルターポップアップの項目
  filterItems: {
    projects: FilterItem[];
    departments: FilterItem[];
    groups: FilterItem[];
  } = {
    departments: [],
    groups: [],
    projects: [],
  };

  // フィルター適用中かどうか
  filtered = false;

  // メンバー一覧
  memberList: MemberInfo[] = [];

  // 表示順
  order: Degree[] = [];
}

class UIMemberFilterGetters extends Getters<UIMemberFilterState> {}

class UIMemberFilterMutations extends Mutations<UIMemberFilterState> {
  clearCondition() {
    this.state.filter.conditions.departments.ids = [];
    this.state.filter.conditions.groups.ids = [];
    this.state.filter.conditions.projects.ids = [];
  }

  setCondition(payload: FilterConditionPayload) {
    this.state.filter.conditions.departments.ids = _.uniq(payload.departments.ids);
    this.state.filter.conditions.projects.ids = _.uniq(payload.projects.ids);
    this.state.filter.conditions.groups.ids = _.uniq(payload.groups.ids);
  }

  setFilterItems(payload: {
    projects: FilterItem[];
    departments: FilterItem[];
    groups: FilterItem[];
  }) {
    this.state.filterItems.projects = _.cloneDeep(payload.projects);
    this.state.filterItems.departments = _.cloneDeep(payload.departments);
    this.state.filterItems.groups = _.cloneDeep(payload.groups);
  }

  setFiltered(payload: { filtered: boolean }) {
    this.state.filtered = payload.filtered;
  }

  setMemberList(payload: { memberList: MemberInfo[] }) {
    this.state.memberList = payload.memberList;
  }

  setOrder(payload: { order: Degree[] }) {
    this.state.order = payload.order;
  }
}

class UIMemberFilterActions extends Actions<
  UIMemberFilterState,
  UIMemberFilterGetters,
  UIMemberFilterMutations,
  UIMemberFilterActions
> {
  // フィルターから設定済みIDをクリアする
  clearCondition() {
    this.mutations.clearCondition();
  }

  clearFiltered() {
    this.mutations.setFiltered({ filtered: false });
  }

  // フィルターに条件を追加する
  setCondition(payload: FilterConditionPayload) {
    this.mutations.setCondition(payload);
  }

  // フィルターアイテムをセットする
  setFilterItems(payload: {
    projects: FilterItem[];
    departments: FilterItem[];
    groups: FilterItem[];
  }) {
    this.mutations.setFilterItems(payload);
  }

  // フィルター適用中かどうかをセットする
  setFiltered(payload: { filtered: boolean }) {
    this.mutations.setFiltered(payload);
  }

  // メンバー一覧をセットする
  setMemberList(payload: { memberList: MemberInfo[] }) {
    this.mutations.setMemberList(payload);
  }

  // 表示順をセットする
  setOrder(payload: { order: Degree[] }) {
    this.mutations.setOrder(payload);
  }
}

export const UIMemberFilter = new Module({
  actions: UIMemberFilterActions,
  getters: UIMemberFilterGetters,
  mutations: UIMemberFilterMutations,
  state: UIMemberFilterState,
});

export const UIMemberFilterMapper = createMapper(UIMemberFilter);
