import _ from 'lodash';
import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';

// メッセージ
export type Message = {
  // メッセージ背景色
  color: string;
  // メッセージテキスト
  text: string;
};

// グリーティング
export type Greeting = {
  imageUrls: string[];
  texts: string[];
};

// ユーザー
export type User = {
  agents: {
    deviceId: string;
    installId: string;
  }[];
  chatUnread: number;
  currentApp: {
    appId: string;
    appImage: string;
    appName: string;
    appTitle: string;
    time: number;
  };
  departments: {
    departmentId: string;
    leader: number;
    departmentName: string;
  }[];
  projects: {
    projectId: string;
    projectName: string;
  }[];
  email: string;
  emotion: number;
  image: string;
  kana: string;
  position: string;
  status: number;
  userId: string;
  userName: string;
  workspaceId: string;
  regDate: string;
  index?: string;
};

// グリーティングの初期値
const initialGreeting: Readonly<Greeting> = Object.freeze({
  imageUrls: [],
  texts: [],
});

// メッセージの初期値
const initialMessage: Readonly<Message> = Object.freeze({
  color: '',
  text: '',
});

class UICommonState {
  // 編集グループID
  editGroupId = '';

  // グリーティング
  greeting: Greeting = _.cloneDeep(initialGreeting);

  // メンバーサークルで表示するユーザー
  memberCircleUserId = '';

  // メッセージ
  message: Message = _.cloneDeep(initialMessage);

  // 画面遷移処理中か
  navigating = false;

  // メンバーポップアップで表示するユーザー（クイックチャットの送信先にもなる）
  popupUserId = '';

  // プロフィールポップアップで表示するユーザー
  profileUserId = '';

  // 出勤簿ポップアップを表示しているかどうか
  showedAttendanceBookPopup = false;

  // 出勤簿編集ポップアップを表示しているかどうか
  showedAttendanceEditPopup = false;

  // フィルターポップアップを表示しているかどうか
  showedFilterPopup = false;

  // グリーティングポップアップを表示しているかどうか
  showedGreetingPopup = false;

  // グループメンバー編集ポップアップを表示しているかどうか
  showedGroupEditorPopup = false;

  // グループ作成初期画面を表示しているかどうか
  showedGroupInitialScreen = false;

  // グループ一覧ポップアップを表示しているかどうか
  showedGroupListPopup = false;

  // グループ設定ポップアップを表示しているかどうか
  showedGroupSettingPopup = false;

  // メンバーサークルを表示しているかどうか
  showedMemberCircle = false;

  // メンバー一覧ポップアップを表示しているかどうか
  showedMemberListPopup = false;

  // メンバーポップアップを表示しているかどうか
  showedMemberPopup = false;

  // ナビゲーションメニューを表示しているかどうか
  showedNavigationMenu = false;

  // ポップアップバックグラウンドレイヤーを表示しているかどうか
  showedPopupBackgroundLayer = false;

  // プロフィールポップアップを表示しているかどうか
  showedProfilePopup = false;

  // クイックチャット一覧ポップアップを表示しているかどうか
  showedQuickChatListPopup = false;

  // クイックチャットポップアップを表示しているかどうか
  showedQuickChatPopup = false;

  // クイックチャット返信ポップアップを表示しているかどうか
  showedQuickChatReplyPopup = false;

  // ユーザーメニューを表示しているかどうか
  showedUserMenu = false;

  // Webプッシュ通知おすすめポップアップを表示しているかどうか
  showedWebPushRecommendPopup = false;

  // ワークスペース情報ポップアップを表示しているかどうか
  showedWorkspacePopup = false;

  // ワークスペースのユーザー一覧
  userList: User[] = [];

  // 表示倍率
  zoom = {
    group: 1,
    home: 1,
  };
}

class UICommonGetters extends Getters<UICommonState> {}

class UICommonMutations extends Mutations<UICommonState> {
  clearEditGroupId() {
    this.state.editGroupId = '';
  }

  clearGreeting() {
    this.state.greeting = _.cloneDeep(initialGreeting);
  }

  clearMemberCircleUserId() {
    this.state.memberCircleUserId = '';
  }

  clearMessage() {
    this.state.message = _.cloneDeep(initialMessage);
  }

  clearPopupUserId() {
    this.state.popupUserId = '';
  }

  clearProfileUserId() {
    this.state.profileUserId = '';
  }

  setEditGroupId(payload: { editGroupId: string }) {
    this.state.editGroupId = payload.editGroupId;
  }

  setGreeting(payload: Greeting) {
    this.state.greeting = payload;
  }

  setMemberCircleUserId(payload: { userId: string }) {
    this.state.memberCircleUserId = payload.userId;
  }

  setMessage(payload: Message) {
    this.state.message = payload;
  }

  setNavigating(payload: { navigating: boolean }) {
    this.state.navigating = payload.navigating;
  }

  setPopupUserId(payload: { userId: string }) {
    this.state.popupUserId = payload.userId;
  }

  setProfileUserId(payload: { userId: string }) {
    this.state.profileUserId = payload.userId;
  }

  setShowedAttendanceBookPopup(payload: { showed: boolean }) {
    this.state.showedAttendanceBookPopup = payload.showed;
  }

  setShowedAttendanceEditPopup(payload: { showed: boolean }) {
    this.state.showedAttendanceEditPopup = payload.showed;
  }

  setShowedFilterPopup(payload: { showed: boolean }) {
    this.state.showedFilterPopup = payload.showed;
  }

  setShowedGreetingPopup(payload: { showed: boolean }) {
    this.state.showedGreetingPopup = payload.showed;
  }

  setShowedGroupEditorPopup(payload: { showed: boolean }) {
    this.state.showedGroupEditorPopup = payload.showed;
  }

  setShowedGroupInitialScreen(payload: { showed: boolean }) {
    this.state.showedGroupInitialScreen = payload.showed;
  }

  setShowedGroupListPopup(payload: { showed: boolean }) {
    this.state.showedGroupListPopup = payload.showed;
  }

  setShowedGroupSettingPopup(payload: { showed: boolean }) {
    this.state.showedGroupSettingPopup = payload.showed;
  }

  setShowedMemberCircle(payload: { showed: boolean }) {
    this.state.showedMemberCircle = payload.showed;
  }

  setShowedMemberListPopup(payload: { showed: boolean }) {
    this.state.showedMemberListPopup = payload.showed;
  }

  setShowedMemberPopup(payload: { showed: boolean }) {
    this.state.showedMemberPopup = payload.showed;
  }

  setShowedNavigationMenu(payload: { showed: boolean }) {
    this.state.showedNavigationMenu = payload.showed;
  }

  setShowedPopupBackgroundLayer(payload: { showed: boolean }) {
    this.state.showedPopupBackgroundLayer = payload.showed;
  }

  setShowedProfilePopup(payload: { showed: boolean }) {
    this.state.showedProfilePopup = payload.showed;
  }

  setShowedQuickChatListPopup(payload: { showed: boolean }) {
    this.state.showedQuickChatListPopup = payload.showed;
  }

  setShowedQuickChatPopup(payload: { showed: boolean }) {
    this.state.showedQuickChatPopup = payload.showed;
  }

  setShowedQuickChatReplyPopup(payload: { showed: boolean }) {
    this.state.showedQuickChatReplyPopup = payload.showed;
  }

  setShowedUserMenu(payload: { showed: boolean }) {
    this.state.showedUserMenu = payload.showed;
  }

  setShowedWebPushRecommendPopup(payload: { showed: boolean }) {
    this.state.showedWebPushRecommendPopup = payload.showed;
  }

  setShowedWorkspacePopup(payload: { showed: boolean }) {
    this.state.showedWorkspacePopup = payload.showed;
  }

  setUserList(payload: { users: User[] }) {
    this.state.userList = payload.users;
  }

  setZoom(payload: { group?: number; home?: number }) {
    if (payload.group) {
      this.state.zoom.group = payload.group;
    }
    if (payload.home) {
      this.state.zoom.home = payload.home;
    }
  }
}

class UICommonActions extends Actions<
  UICommonState,
  UICommonGetters,
  UICommonMutations,
  UICommonActions
> {
  // 編集グループIDをクリアする
  clearEditGroupId() {
    this.mutations.clearEditGroupId();
  }

  // グリーティングをクリアする
  clearGreeting() {
    this.mutations.clearGreeting();
  }

  // メンバーサークルのユーザーIDをクリアする
  clearMemberCircleUserId() {
    this.mutations.clearMemberCircleUserId();
  }

  // メッセージをクリアする
  clearMessage() {
    this.mutations.clearMessage();
  }

  // メンバーポップアップのユーザーIDをクリアする
  clearPopupUserId() {
    this.mutations.clearPopupUserId();
  }

  // プロフィールポップアップに表示するユーザーをクリアする
  clearProfileUserId() {
    this.mutations.clearProfileUserId();
  }

  // すべてのポップアップを閉じる
  hideAllPopup() {
    this.mutations.clearPopupUserId();
    this.mutations.setShowedAttendanceBookPopup({ showed: false });
    this.mutations.setShowedAttendanceEditPopup({ showed: false });
    this.mutations.setShowedFilterPopup({ showed: false });
    this.mutations.setShowedGreetingPopup({ showed: false });
    this.mutations.setShowedGroupEditorPopup({ showed: false });
    this.mutations.setShowedGroupListPopup({ showed: false });
    this.mutations.setShowedGroupSettingPopup({ showed: false });
    this.mutations.setShowedMemberCircle({ showed: false });
    this.mutations.setShowedMemberListPopup({ showed: false });
    this.mutations.setShowedMemberPopup({ showed: false });
    this.mutations.setShowedProfilePopup({ showed: false });
    this.mutations.setShowedUserMenu({ showed: false });
    this.mutations.setShowedQuickChatListPopup({ showed: false });
    this.mutations.setShowedQuickChatPopup({ showed: false });
    this.mutations.setShowedQuickChatReplyPopup({ showed: false });
    this.mutations.setShowedWorkspacePopup({ showed: false });
    this.mutations.setShowedPopupBackgroundLayer({ showed: false });
  }

  // 出勤簿ポップアップを閉じる
  hideAttendanceBookPopup() {
    this.mutations.setShowedAttendanceBookPopup({ showed: false });
  }

  // 出勤簿編集ポップアップを閉じる
  hideAttendanceEditPopup() {
    this.mutations.setShowedAttendanceEditPopup({ showed: false });
  }

  // フィルターポップアップを閉じる
  hideFilterPopup() {
    this.mutations.setShowedFilterPopup({ showed: false });
  }

  // グリーティングポップアップを閉じる
  hideGreetingPopup() {
    this.mutations.setShowedGreetingPopup({ showed: false });
  }

  // グループメンバー編集ポップアップを閉じる
  hideGroupEditorPopup() {
    this.mutations.setShowedGroupEditorPopup({ showed: false });
  }

  // グループ作成初期画面を閉じる
  hideGroupInitialScreen() {
    this.mutations.setShowedGroupInitialScreen({ showed: false });
  }

  // グループ一覧ポップアップを閉じる
  hideGroupListPopup() {
    this.mutations.setShowedGroupListPopup({ showed: false });
  }

  // グループ設定ポップアップを閉じる
  hideGroupSettingPopup() {
    this.mutations.setShowedGroupSettingPopup({ showed: false });
  }

  // メンバーサークルを閉じる
  hideMemberCircle() {
    this.mutations.setShowedMemberCircle({ showed: false });
  }

  // メンバー一覧ポップアップを閉じる
  hideMemberListPopup() {
    this.mutations.setShowedMemberListPopup({ showed: false });
  }

  // メンバーポップアップを閉じる
  hideMemberPopup() {
    this.mutations.setShowedMemberPopup({ showed: false });
  }

  // ポップアップバックグラウンドレイヤーを閉じる
  hidePopupBackgroundLayer() {
    this.mutations.setShowedPopupBackgroundLayer({ showed: false });
  }

  // プロフィールポップアップを閉じる
  hideProfilePopup() {
    this.mutations.setShowedProfilePopup({ showed: false });
  }

  // クイックチャット一覧ポップアップを閉じる
  hideQuickChatListPopup() {
    this.mutations.setShowedQuickChatListPopup({ showed: false });
  }

  // クイックチャットポップアップを閉じる
  hideQuickChatPopup() {
    this.mutations.setShowedQuickChatPopup({ showed: false });
  }

  // クイックチャット返信ポップアップを閉じる
  hideQuickChatReplyPopup() {
    this.mutations.setShowedQuickChatReplyPopup({ showed: false });
  }

  // ユーザーメニューを閉じる
  hideUserMenu() {
    this.mutations.setShowedUserMenu({ showed: false });
  }

  // Webプッシュ通知おすすめポップアップをを閉じる
  hideWebPushRecommendPopup() {
    this.mutations.setShowedWebPushRecommendPopup({ showed: false });
  }

  // ワークスペース情報ポップアップを閉じる
  hideWorkspacePopup() {
    this.mutations.setShowedWorkspacePopup({ showed: false });
  }

  // メッセージを設定する
  setEditGroupId(payload: { editGroupId: string }) {
    this.mutations.setEditGroupId(payload);
  }

  // エラーメッセージを設定する (背景色はメッセージ内のエラーコードに合わせて設定する)
  setErrorMessage(payload: { text: string }) {
    const codeRegExp = /^.* \[([A-Z][^-]+)-([^-])-([0-9]{2,5})-([0-9]{4,8})\]$/i;
    const matched = codeRegExp.exec(payload.text);
    let color = 'error';

    if (_.isArray(matched)) {
      switch (matched[2].toUpperCase()) {
        case 'C':
        case 'D':
        case 'I':
          color = 'info';
          break;
        case 'W':
          color = 'warning';
          break;
        default:
          color = 'error';
          break;
      }
    }

    this.mutations.setMessage({ color, text: payload.text });
  }

  // グリーティングを設定する
  setGreeting(payload: Greeting) {
    this.mutations.setGreeting(payload);
  }

  // メンバーサークルのユーザーIDをセットする
  setMemberCircleUserId(payload: { userId: string }) {
    this.mutations.setMemberCircleUserId(payload);
  }

  // メッセージを設定する
  setMessage(payload: Message) {
    this.mutations.setMessage(payload);
  }

  // 画面遷移処理中かどうかを設定する
  setNavigating(payload: { navigating: boolean }) {
    this.mutations.setNavigating(payload);
  }

  // メンバーポップアップのユーザーIDをセットする
  setPopupUserId(payload: { userId: string }) {
    this.mutations.setPopupUserId(payload);
  }

  // プロフィールポップアップに表示するユーザーを設定する
  setProfileUserId(payload: { userId: string }) {
    this.mutations.setProfileUserId(payload);
  }

  // ナビゲーションメニュー表示有無を設定する
  setShowedNavigationMenu(payload: { showed: boolean }) {
    this.mutations.setShowedNavigationMenu(payload);
  }

  // ユーザーメニュー表示有無を設定する
  setShowedUserMenu(payload: { showed: boolean }) {
    this.mutations.setShowedUserMenu(payload);
  }

  // ユーザー一覧を設定する
  setUserList(payload: { users: User[] }) {
    this.mutations.setUserList(payload);
  }

  // 表示倍率を設定する
  setZoom(payload: { group?: number; home?: number }) {
    this.mutations.setZoom(payload);
  }

  // 出勤簿ポップアップを表示する
  showAttendanceBookPopup() {
    this.mutations.setShowedAttendanceBookPopup({ showed: true });
  }

  // 出勤簿編集ポップアップを表示する
  showAttendanceEditPopup() {
    this.mutations.setShowedAttendanceEditPopup({ showed: true });
  }

  // フィルターポップアップを表示する
  showFilterPopup() {
    this.mutations.setShowedFilterPopup({ showed: true });
  }

  // グリーティングポップアップを表示する
  showGreetingPopup() {
    this.mutations.setShowedGreetingPopup({ showed: true });
  }

  // グループメンバー編集ポップアップを表示する
  showGroupEditorPopup() {
    this.mutations.setShowedGroupEditorPopup({ showed: true });
  }

  // グループ作成初期画面を表示する
  showGroupInitialScreen() {
    this.mutations.setShowedGroupInitialScreen({ showed: true });
  }

  // グループ一覧ポップアップを表示する
  showGroupListPopup() {
    this.mutations.setShowedGroupListPopup({ showed: true });
  }

  // グループ設定ポップアップを表示する
  showGroupSettingPopup() {
    this.mutations.setShowedGroupSettingPopup({ showed: true });
  }

  // メンバーサークルを表示する
  showMemberCircle() {
    this.mutations.setShowedMemberCircle({ showed: true });
  }

  // メンバー一覧ポップアップを表示する
  showMemberListPopup() {
    this.mutations.setShowedMemberListPopup({ showed: true });
  }

  // メンバーポップアップを表示する
  showMemberPopup() {
    this.mutations.setShowedMemberPopup({ showed: true });
  }

  // ポップアップバックグラウンドレイヤーを表示する
  showPopupBackgroundLayer() {
    this.mutations.setShowedPopupBackgroundLayer({ showed: true });
  }

  // プロフィールポップアップを表示する
  showProfilePopup() {
    this.mutations.setShowedProfilePopup({ showed: true });
  }

  // クイックチャット一覧ポップアップを表示する
  showQuickChatListPopup() {
    this.mutations.setShowedQuickChatListPopup({ showed: true });
  }

  // クイックチャットポップアップを表示する
  showQuickChatPopup() {
    this.mutations.setShowedQuickChatPopup({ showed: true });
  }

  // クイックチャット返信ポップアップを表示する
  showQuickChatReplyPopup() {
    this.mutations.setShowedQuickChatReplyPopup({ showed: true });
  }

  // Webプッシュ通知おすすめポップアップを表示する
  showWebPushRecommendPopup() {
    this.mutations.setShowedWebPushRecommendPopup({ showed: true });
  }

  // ワークスペース情報ポップアップを表示する
  showWorkspacePopup() {
    this.mutations.setShowedWorkspacePopup({ showed: true });
  }

  // ナビゲーションメニュー表示有無を切り替える
  toggleShowedNavigationMenu() {
    this.mutations.setShowedNavigationMenu({ showed: !this.state.showedNavigationMenu });
  }
}

export const UICommon = new Module({
  actions: UICommonActions,
  getters: UICommonGetters,
  mutations: UICommonMutations,
  state: UICommonState,
});

export const UICommonMapper = createMapper(UICommon);
