import _ from 'lodash';
import Vue from 'vue';

// 文字列を表示用の日時文字列に変換 (文字列パース/日時表示のデフォルトフォーマット使用)
// string: 変換したい文字列
const parseAndFormatToDateTimeString = function parseAndFormatToDateTimeString(
  this: Vue,
  string: string
) {
  if (!_.isNil(string)) {
    const tempDayjs = this.$$dayjsParse(string);

    return this.$$dayjsFormatToDateTimeString(tempDayjs);
  }

  return '';
};

export default parseAndFormatToDateTimeString;
