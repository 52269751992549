import axios from 'axios';
import _ from 'lodash';
import Log from '@/resources/plugins/Logger/Log';
import settings from '@/settings';

const Service = axios.create({
  baseURL: settings.apiEndpoint,
  headers: {
    'Content-Type': 'application/json',
  },
});

Service.interceptors.request.use(
  (config) => {
    Log.debug('Request Config', config);

    return config;
  },
  (error) => {
    const newError = {
      errCode: '',
      message: '',
      status: '',
    };

    newError.message = _.get(error, 'response.data.message');
    newError.errCode = _.get(error, 'response.data.errCode');
    newError.status = _.get(error, 'response.status');

    Log.error('Service Error', error);
    Log.error('Service Error Request', error.request);

    return Promise.reject(newError);
  }
);

Service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const newError = {
      errCode: '',
      message: '',
      status: '',
    };

    newError.message = _.get(error, 'response.data.message');
    newError.errCode = _.get(error, 'response.data.errCode');
    newError.status = _.get(error, 'response.status');

    Log.error('Service Error', error);
    Log.error('Service Error Response', error.response);

    return Promise.reject(newError);
  }
);

export default Service;
