import _dayjs from 'dayjs';
import _Vue from 'vue';
import DayjsFormats from './DayjsFormats';
import formatToDateString from './formatToDateString';
import formatToDateTimeString from './formatToDateTimeString';
import parse from './parse';
import parseAndFormatToDateString from './parseAndFormatToDateString';
import parseAndFormatToDateTimeString from './parseAndFormatToDateTimeString';
import stringify from './stringify';

// Day.jsラッパープラグイン
// Day.js、フォーマット定義、変換処理を追加する
const DayjsWrapper = {
  install(Vue: typeof _Vue, options: { dayjs: typeof _dayjs }) {
    // Day.js
    Vue.prototype.$$dayjs = options.dayjs;

    // フォーマット定義
    Vue.prototype.$$dayjsFormats = DayjsFormats;

    // 変換処理
    Vue.prototype.$$dayjsParse = parse;
    Vue.prototype.$$dayjsFormatToDateString = formatToDateString;
    Vue.prototype.$$dayjsFormatToDateTimeString = formatToDateTimeString;
    Vue.prototype.$$dayjsParseAndFormatToDateString = parseAndFormatToDateString;
    Vue.prototype.$$dayjsParseAndFormatToDateTimeString = parseAndFormatToDateTimeString;
    Vue.prototype.$$dayjsStringify = stringify;
  },
};

export default DayjsWrapper;
