import ValidationMessages from './ValidationMessages';
import Validators from './Validators';
import generateMessage from './generateMessage';
import generateMessageIfError from './generateMessageIfError';
import getIsValidIfDirty from './getIsValidIfDirty';
import getIsValidIfError from './getIsValidIfError';
import type _Vue from 'vue';

// Vuelidate拡張プラグイン
// バリデータ、エラーメッセージの定義、有効性チェック処理、エラーメッセージの生成処理を追加する
const VuelidateExtension = {
  // Vue: Vueコントラクタ
  install(Vue: typeof _Vue) {
    // エラーメッセージ
    Vue.prototype.$$validationMessages = ValidationMessages;

    // バリデータ
    Vue.prototype.$$validators = Validators;

    // 有効性チェック処理
    Vue.prototype.$$getIsValidIfDirty = getIsValidIfDirty;
    Vue.prototype.$$getIsValidIfError = getIsValidIfError;

    // エラーメッセージ生成処理
    Vue.prototype.$$generateMessage = generateMessage;
    Vue.prototype.$$generateMessageIfError = generateMessageIfError;
  },
};

export default VuelidateExtension;
