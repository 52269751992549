import _ from 'lodash';
import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';

class UINotificationState {
  // Webプッシュ通知トークン（ユーザー別）
  webPushToken: {
    [userId: string]: string;
  } = {};
}

class UINotificationGetters extends Getters<UINotificationState> {
  // 特定ユーザーのトークン
  webPushToken(userId: string) {
    return this.state.webPushToken[userId] || '';
  }
}

class UINotificationMutations extends Mutations<UINotificationState> {
  addUser(payload: { userId: string }) {
    this.state.webPushToken[payload.userId] = '';
  }

  removeWebPushToken(payload: { userId: string }) {
    this.state.webPushToken[payload.userId] = '';
  }

  updateWebPushToken(payload: { token: string; userId: string }) {
    this.state.webPushToken[payload.userId] = payload.token;
  }
}

class UINotificationActions extends Actions<
  UINotificationState,
  UINotificationGetters,
  UINotificationMutations,
  UINotificationActions
> {
  removeWebPushToken(payload: { userId: string }) {
    this.mutations.removeWebPushToken(payload);
  }

  updateWebPushToken(payload: { token: string; userId: string }) {
    const self = this;
    const usersToken = self.state.webPushToken[payload.userId];

    if (usersToken === undefined) {
      self.mutations.addUser({ userId: payload.userId });
    }

    self.mutations.updateWebPushToken(payload);
  }
}

export const UINotification = new Module({
  actions: UINotificationActions,
  getters: UINotificationGetters,
  mutations: UINotificationMutations,
  state: UINotificationState,
});

export const UINotificationMapper = createMapper(UINotification);
