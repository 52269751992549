import _ from 'lodash';
import Vue from 'vue';

// 文字列を表示用の日付文字列に変換 (文字列パース/日付表示のデフォルトフォーマット使用)
// string: 変換したい文字列
const parseAndFormatToDateString = function parseAndFormatToDateString(this: Vue, string: string) {
  if (!_.isNil(string)) {
    const tempDayjs = this.$$dayjsParse(string);

    return this.$$dayjsFormatToDateString(tempDayjs);
  }

  return '';
};

export default parseAndFormatToDateString;
