import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  // 部署メンバーを登録する
  async createDepartmentMember(params: {
    workspaceId: string;
    departmentId: string;
    memberId: string;
    leader: number;
  }) {
    try {
      const response = await Service.post(`/departmentmember/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('createDepartmentMember#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('createDepartmentMember#Error', error);

      throw error;
    }
  },

  // 指定された部署のメンバーを削除
  async deleteDepartmentMember(workspaceId: string, departmentId: string, memberId: string) {
    try {
      const response = await Service.delete(
        `/departmentmember/${workspaceId}/${departmentId}/${memberId}`,
        {
          headers: generateHeaders('Bearer', { Accept: 'application/json' }),
        }
      );

      Log.debug('deleteDepartmentMember#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('deleteDepartmentMember#Error', error);

      switch (error.status) {
        case 400:
          error.message = '不正なリクエストです。';
          break;
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = '部署メンバーが見つかりません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 指定された部署のメンバー一覧を返す
  async getDepartmentMemberList(workspaceId: string, departmentId: string) {
    try {
      const response = await Service.get(`/departmentmember/list/${workspaceId}/${departmentId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getDepartmentMemberList#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getDepartmentMemberList#Error', error);

      throw error;
    }
  },
};
