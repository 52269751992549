import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  // 文言情報を登録
  async createPhrase(params: { workspaceId: string; kind: string; text: string }) {
    try {
      const response = await Service.post(`/phrase/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('createPhrase#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('createPhrase#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 文言情報を削除
  async deletePhrase(workspaceId: string, phraseId: string) {
    try {
      const response = await Service.delete(`/phrase/${workspaceId}/${phraseId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('deletePhrase#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('deletePhrase#Error', error);

      switch (error.status) {
        case 400:
          error.message = '正しい文書情報が送られていません。';
          break;
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = '存在しないユーザーです。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 指定された文書情報を返す
  async getPhrase(workspaceId: string, phraseId: string) {
    try {
      const response = await Service.get(`/phrase/${workspaceId}/${phraseId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getPhrase#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getPhrase#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = '存在しないユーザーです。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 指定されたワークスペースの文書一覧を返す
  async getPhraseList(workspaceId: string) {
    try {
      const response = await Service.get(`/phrase/list/${workspaceId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getPhraseList#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getPhraseList#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 文言情報を更新
  async updatePhrase(
    workspaceId: string,
    phraseId: string,
    params: { kind: string; text: string }
  ) {
    try {
      const response = await Service.put(
        `/phrase/${workspaceId}/${phraseId}`,
        JSON.stringify(params),
        {
          headers: generateHeaders('Bearer', { Accept: 'application/json' }),
        }
      );

      Log.debug('updatePhrase#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('updatePhrase#Error', error);

      switch (error.status) {
        case 400:
          error.message = '正しい文書情報が送られていません。';
          break;
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = '存在しないユーザーです。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },
};
