






















import { mdiPlus, mdiMinus } from '@mdi/js';
import Vue from 'vue';

export default Vue.extend({
  name: 'BasePanel',

  inheritAttrs: false,

  props: {
    // タイトルの後ろに追加するアイコン
    appendIcon: {
      type: String,
      default: undefined,
    },

    // 折り畳み状態かどうか (初期値及び親からの変更通知用)
    collapsed: {
      type: Boolean,
    },

    // 折り畳み/展開が無効かどうか
    disabled: {
      type: Boolean,
    },

    // ヘッダーの背景色
    headerColor: {
      type: String,
      default: undefined,
    },

    // ヘッダーにダークテーマを適用するか
    headerDark: {
      type: Boolean,
    },

    // タイトルの手前に追加するアイコン
    prependIcon: {
      type: String,
      default: undefined,
    },

    // パネルタイトル
    title: {
      type: String,
      required: true,
    },
  },

  data(): {
    collapsedInComponent: boolean | undefined;
    icons: {
      [key: string]: string;
    };
  } {
    return {
      // 折り畳み状態かどうか (コンポーネント内の制御用)
      collapsedInComponent: undefined,
      // アイコン
      icons: {
        mdiMinus,
        mdiPlus,
      },
    };
  },

  watch: {
    // 折り畳み状態かどうかが親で変更された場合、それをコンポーネント内に反映する
    collapsed: {
      immediate: true,
      handler(value) {
        this.collapsedInComponent = value;
      },
    },
  },

  methods: {
    // 折り畳み/展開を切り替える
    toggleCollapse() {
      if (this.disabled) {
        return;
      }

      // コンポーネント内の値を更新すると共に、親に更新を通知するためにイベントを発行する
      this.collapsedInComponent = !this.collapsedInComponent;
      this.$emit('update:collapsed', this.collapsedInComponent);
    },
  },
});
