import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  // プロジェクト情報を登録する
  async createProject(params: { workspaceId: string; projectName: string; mainFlag: number }) {
    try {
      const response = await Service.post(`/project/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('createProject#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('createProject#Error', error);

      throw error;
    }
  },

  // プロジェクトを削除
  async deleteProject(workspaceId: string, projectId: string) {
    try {
      const response = await Service.delete(`/project/${workspaceId}/${projectId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('deleteProject#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('deleteProject#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = 'プロジェクトが見つかりません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // 指定されたワークスペースのプロジェクト一覧を返す
  async getProjectList(workspaceId: string) {
    try {
      const response = await Service.get(`/project/list/${workspaceId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getProjectList#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getProjectList#Error', error);

      throw error;
    }
  },

  // プロジェクト情報を更新
  async updateProject(workspaceId: string, projectId: string, params: { projectName: string }) {
    try {
      const response = await Service.put(
        `/project/${workspaceId}/${projectId}`,
        JSON.stringify(params),
        {
          headers: generateHeaders('Bearer', { Accept: 'application/json' }),
        }
      );

      Log.debug('updateProject#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('updateProject#Error', error);

      switch (error.status) {
        case 400:
          error.message = '不正なリクエストです。';
          break;
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = 'プロジェクトが見つかりません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },
};
