import type { ValidationStates } from './@types/VuelidateExtension.d';

// 有効性チェック処理
// validationStates: 検証対象に設定されているすべてのバリデーションの状態
const getIsValidIfError = (validationStates: ValidationStates) => {
  if (validationStates.$error) {
    return false;
  }

  return undefined;
};

export default getIsValidIfError;
