import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  // WebPush設定情報を取得する
  async getWebPushConfig() {
    try {
      const response = await Service.get(`/webpush/config/`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getWebPushConfig#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getWebPushConfig#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 404:
          error.message = '設定が存在しません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // WebPush通知を行う
  async notify(params: {
    workspaceId: string;
    userId: string;
    title: string;
    body: string;
    url: string;
  }) {
    try {
      const response = await Service.post(`/webpush/notify/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('notify#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('notify#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // WebPush購読申込をする
  async subscribe(params: { workspaceId: string; userId: string; token: string }) {
    try {
      const response = await Service.post(`/webpush/subscribe/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('subscribe#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('subscribe#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },

  // WebPush購読中止をする
  async unsubscribe(params: { workspaceId: string; userId: string; token: string }) {
    try {
      const response = await Service.post(`/webpush/unsubscribe/`, JSON.stringify(params), {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('unsubscribe#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('unsubscribe#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },
};
