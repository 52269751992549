import type { ShineConnectSettings } from '@/settings/index';

// 設計書記載の順序で定義する
/* eslint-disable vue/sort-keys */

const settingsStaging: ShineConnectSettings = {
  messageCloseWaitTime: 15,
  apiEndpoint: 'https://api.stg.shineconnect.jp/v1',
  apiKey: '',
  webSocket: {
    url: 'wss://api.stg.shineconnect.jp/',
    cookieDomain: '.stg.shineconnect.jp',
    options: {
      connectionTimeout: 1000,
      maxReconnectAttempts: 5,
      reconnectInterval: 3000,
    },
  },
  agent: {
    url: {
      win: 'https://agent.stg.shineconnect.jp/sc-agent-x64-latest.zip',
      mac: 'https://agent.stg.shineconnect.jp/sc-agent-x64-latest.dmg',
    },
  },
  attendance: {
    atdBorderTime: '05:00',
    atdLimitTime: '15',
  },
  toast: {
    instanceOptions: {
      transition: 'fade',
      maxToasts: 3,
      newestOnTop: true,
      shareAppContext: true,
    },
    messageOptions: {
      position: 'top-right',
      timeout: 5000,
      closeOnClick: false,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: true,
      hideProgressBar: true,
      hideCloseButton: true,
      icon: false,
      rtl: false,
    },
  },
  deployTargetInPageTitle: 'STG',
  deployTargetName: 'ステージング環境',
  defaultTimezone: 'Asia/Tokyo',
  helpButtonItems: {},
  externalLinkURLs: {
    browserPhone: 'https://usr.stginopbx.com/pbx/browserphone/Phone/',
    loginIssue: '',
    news: 'https://comsq.com',
    privacyPolicy: 'https://comsq.com',
  },
  mainDomainURL: 'https://console.stg.shineconnect.jp',
  interfaceAPIEndpoint: '',
  strapiAPIEndpoint: '',
  strapiImageBaseURL: '',
};
/* eslint-enable vue/sort-keys */

export default settingsStaging;
