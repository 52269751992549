import Vue from 'vue';
import Log from '../Logger/Log';

// 文字列をDayjsに変換 (文字列パースのデフォルトフォーマット使用)
// string: 変換したい文字列
const parse = function parse(this: Vue, string: string) {
  const ret = this.$$dayjs(string, this.$$dayjsFormats.parseFormatDefault);

  if (!this.$$dayjs.isDayjs(ret)) {
    Log.debug('Dayjsに変換できません。', string);
  } else if (!ret.isValid()) {
    Log.debug('無効な日時です。', string, ret);
  }

  return ret;
};

export default parse;
