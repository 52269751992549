import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  // 指定されたユーザーの他のメンバーとの繋がり度リストを返す
  async getRelationship(workspaceId: string, userId: string) {
    try {
      const response = await Service.get(`/relationship/${workspaceId}/${userId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getRelationship#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getRelationship#Error', error);

      throw error;
    }
  },

  // 指定されたユーザーの他のメンバーとの繋がり度リスト（ソート済み）を返す
  async getRelationshipSorted(workspaceId: string, userId: string) {
    try {
      const response = await Service.get(`/relationship/sort/${workspaceId}/${userId}`, {
        headers: generateHeaders('Bearer', { Accept: 'application/json' }),
      });

      Log.debug('getRelationshipSorted#Response', response);

      return response.data;
    } catch (error: any) {
      Log.error('getRelationshipSorted#Error', error);

      switch (error.status) {
        case 401:
          error.message = '認証されていません。';
          break;
        case 403:
          error.message = '権限がありません。';
          break;
        case 404:
          error.message = '繋がり度が見つかりません。';
          break;
        case 500:
          error.message = '内部エラーが発生しました。';
          break;
        default:
          error.message = '予期しないエラーが発生しました。';
      }

      throw error;
    }
  },
};
