import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';

// 役職
export type Position = {
  positionId: string;
  name: string;
  regDate: string;
  workspaceId: string;
  status: number;
};

class UIPositionState {
  // 役職一覧
  positionList: Position[] = [];
}

class UIPositionGetters extends Getters<UIPositionState> {}

class UIPositionMutations extends Mutations<UIPositionState> {
  setPositionList(payload: Position[]) {
    this.state.positionList = payload;
  }
}

class UIPositionActions extends Actions<
  UIPositionState,
  UIPositionGetters,
  UIPositionMutations,
  UIPositionActions
> {
  // 役職情報をセットする
  setPositionList(payload: Position[]) {
    this.mutations.setPositionList(payload);
  }
}

export const UIPosition = new Module({
  actions: UIPositionActions,
  getters: UIPositionGetters,
  mutations: UIPositionMutations,
  state: UIPositionState,
});

export const UIPositionMapper = createMapper(UIPosition);
