import type { ValidationStates } from './@types/VuelidateExtension.d';

// 有効性チェック処理
// validationStates: 検証対象に設定されているすべてのバリデーションの状態
const getIsValidIfDirty = (validationStates: ValidationStates) => {
  if (validationStates.$dirty) {
    return !validationStates.$invalid;
  }

  return undefined;
};

export default getIsValidIfDirty;
